import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FTextArea } from "CommonElements/Form";
import CommonModal from "../../../Common/Data/Ui-kits/Modals/common/modal";
import { transactionsService } from "Services";
import { toast } from "react-toastify";
import { ModalFooter } from "reactstrap";
import { Close, SaveChanges } from "../../../../Constant";

const RejectMultipleFormModal = ({ 
  rejectModal, 
  NewMessage, 
  rejecttl, 
  setRejectModal, 
  rejectData, 
  refreshRecord,
  userId // Add userId to track the user rejecting the transactions
}) => {
  const [submitState, setSubmitState] = useState({ loading: false });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!rejectModal) {
      clearErrors();
      reset();
    } else {
      const formFields = getValues();
      Object.keys(formFields).forEach((key) => {
        if (rejectData[key]) {
          setValue(key, rejectData[key]);
        }
      });
    }
  }, [rejectModal]);

  const onSubmit = async (data) => {
    if (data !== "") {
      setSubmitState({ loading: true });

      const transactionIds = rejectData.map(item => item.id); // Extract IDs

      const payload = {
        ids: transactionIds,
        note: data.note,
        reason: data.reason,
        updatedBy: userId, // Add updatedBy field
      };

      try {
        const response = await transactionsService.postRejectMultipleTransactions(payload);
        setSubmitState({ loading: false });
        refreshRecord();
        setRejectModal(false);
        toast.success("Transactions Rejected Successfully");
      } catch (error) {
        setSubmitState({ loading: false });
        toast.error("Failed to reject transactions");
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <CommonModal
        isOpen={rejectModal}
        title={NewMessage}
        toggler={rejecttl}
        isButtonShown={true}
      >
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ marginBottom: 10 }}>
            <Col md="12">
              <FTextArea
                errors={errors}
                label="Reason:"
                name="reason"
                register={register}
                placeholder="Enter Reason"
                validation={{ required: true }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md="12">
              <FTextArea
                errors={errors}
                label="Note:"
                name="note"
                register={register}
                placeholder="Enter Note"
                validation={{ required: true }}
              />
            </Col>
          </Row>
          <ModalFooter>
            <Btn attrBtn={{ color: "secondary", onClick: () => setRejectModal(false), type: "button" }}>
              {Close}
            </Btn>
            <Btn disabled={submitState.loading} attrBtn={{ color: "primary" }}>
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default RejectMultipleFormModal;
