import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const PersonalInfoModal = ({ isOpen, toggle, formValues, handleFormChange, handleSaveUserInfo }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Personal Info</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="gender">Gender</Label>
            <Input
              type="select"
              name="gender"
              id="gender"
              value={formValues.gender}
              onChange={handleFormChange}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="birth_date">Birth Date</Label>
            <Input
              type="date"
              name="birth_date"
              id="birth_date"
              value={formValues.birth_date}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="marital_status">Marital Status</Label>
            <Input
              type="select"
              name="marital_status"
              id="marital_status"
              value={formValues.marital_status}
              onChange={handleFormChange}
            >
              <option value="">Select Marital Status</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Not Mentioned">Not Mentioned</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="nationality">Nationality</Label>
            <Input
              type="select"
              name="nationality"
              id="nationality"
              value={formValues.nationality}
              onChange={handleFormChange}
            >
              <option value="">Select Nationality</option>
              {[
                "Afghan",
                "Albanian",
                "Algerian",
                "American",
                "Andorran",
                "Angolan",
                "Antiguan and Barbudan",
                "Argentine",
                "Armenian",
                "Australian",
                "Austrian",
                "Azerbaijani",
                "Bahamian",
                "Bahraini",
                "Bangladeshi",
                "Barbadian",
                "Belarusian",
                "Belgian",
                "Belizean",
                "Beninese",
                "Bhutanese",
                "Bolivian",
                "Bosnian and Herzegovinian",
                "Botswanan",
                "Brazilian",
                "British",
                "Bruneian",
                "Bulgarian",
                "Burkinabé",
                "Burmese",
                "Burundian",
                "Cambodian",
                "Cameroonian",
                "Canadian",
                "Cape Verdean",
                "Central African",
                "Chadian",
                "Chilean",
                "Chinese",
                "Colombian",
                "Comoran",
                "Congolese (Congo-Brazzaville)",
                "Congolese (Congo-Kinshasa)",
                "Costa Rican",
                "Croatian",
                "Cuban",
                "Cypriot",
                "Czech",
                "Danish",
                "Djiboutian",
                "Dominican",
                "Dutch",
                "East Timorese",
                "Ecuadorean",
                "Egyptian",
                "Emirati",
                "Equatorial Guinean",
                "Eritrean",
                "Estonian",
                "Eswatini",
                "Ethiopian",
                "Fijian",
                "Filipino",
                "Finnish",
                "French",
                "Gabonese",
                "Gambian",
                "Georgian",
                "German",
                "Ghanaian",
                "Greek",
                "Grenadian",
                "Guatemalan",
                "Guinean",
                "Guyanese",
                "Haitian",
                "Honduran",
                "Hungarian",
                "Icelandic",
                "Indian",
                "Indonesian",
                "Iranian",
                "Iraqi",
                "Irish",
                "Israeli",
                "Italian",
                "Ivorian",
                "Jamaican",
                "Japanese",
                "Jordanian",
                "Kazakh",
                "Kenyan",
                "Kuwaiti",
                "Kyrgyz",
                "Laotian",
                "Latvian",
                "Lebanese",
                "Liberian",
                "Libyan",
                "Liechtensteiner",
                "Lithuanian",
                "Luxembourger",
                "Malagasy",
                "Malawian",
                "Malaysian",
                "Maldivian",
                "Malian",
                "Maltese",
                "Mauritanian",
                "Mauritian",
                "Mexican",
                "Moldovan",
                "Monacan",
                "Mongolian",
                "Montenegrin",
                "Moroccan",
                "Mozambican",
                "Namibian",
                "Nepalese",
                "New Zealander",
                "Nicaraguan",
                "Nigerien",
                "Nigerian",
                "North Korean",
                "North Macedonian",
                "Norwegian",
                "Omani",
                "Pakistani",
                "Palestinian",
                "Panamanian",
                "Papua New Guinean",
                "Paraguayan",
                "Peruvian",
                "Polish",
                "Portuguese",
                "Qatari",
                "Romanian",
                "Russian",
                "Rwandan",
                "Saint Lucian",
                "Salvadoran",
                "Samoan",
                "Saudi",
                "Senegalese",
                "Serbian",
                "Seychellois",
                "Sierra Leonean",
                "Singaporean",
                "Slovak",
                "Slovenian",
                "Somali",
                "South African",
                "South Korean",
                "South Sudanese",
                "Spanish",
                "Sri Lankan",
                "Sudanese",
                "Surinamese",
                "Swedish",
                "Swiss",
                "Syrian",
                "Tajik",
                "Tanzanian",
                "Thai",
                "Togolese",
                "Tongan",
                "Trinidadian and Tobagonian",
                "Tunisian",
                "Turkish",
                "Turkmen",
                "Tuvaluan",
                "Ugandan",
                "Ukrainian",
                "Uruguayan",
                "Uzbek",
                "Vanuatuan",
                "Venezuelan",
                "Vietnamese",
                "Yemeni",
                "Zambian",
                "Zimbabwean"
              ].map((nationality) => (
                <option key={nationality} value={nationality}>
                  {nationality}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="national_id">National ID</Label>
            <Input
              type="text"
              name="national_id"
              id="national_id"
              value={formValues.national_id}
              onChange={handleFormChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveUserInfo}>
          Update
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PersonalInfoModal;
