import api from './api';

export const fetchUsersCommissions = () => {
    return api.get(`/v1/crm/UsersCommissions`);
};

export const createUsersCommissions = (data) => {
    return api.post(`/v1/crm/UsersCommissions`, data);
};

export const updateUsersCommissions= (id, data) => {
    return api.put(`/v1/crm/UsersCommissions/${id}`, data);
};

export const deleteUsersCommissions = (id) => {
    return api.delete(`/v1/crm/UsersCommissions/${id}`);
};
    