import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Row, Col, Media, Input, Button } from 'reactstrap';
import Accounts from '../../../assets/images/avtar/dashboardIcon.svg';
import { Doughnut, Line } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { LineElement, CategoryScale, LinearScale, PointElement } from "chart.js";
import BluewalletIcon from '../../../assets/images/blueWalletMarketing.svg';
import PinkwalletIcon from '../../../assets/images/pinkWalletMarketing.svg';
import Image1 from "../../../assets/images/godounknowpic.svg"; // Local fallback image
import Image2 from "../../../assets/images/test-profile/Image1.png";
import Image3 from "../../../assets/images/test-profile/Image2.png";
import Image4 from "../../../assets/images/test-profile/Image3.png";
import { MarketReport } from 'Services';
import { getDocumentSas } from "Services/usersPersonalDetail.service"; // API to fetch images
import { useNavigate } from "react-router-dom";


// Register Chart.js components
Chart.register(LineElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, ArcElement);



const Dashboard = () => {

  const [groupedSalesData, setGroupedSalesData] = useState({});
  const [agentImages, setAgentImages] = useState({});
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lineChartData, setLineChartData] = useState({ datasets: [] });
  // Inside the Dashboard component:
  const navigate = useNavigate();
  // Filters
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [selectedSource, setSelectedSource] = useState('');  // Fix: Add this
  const [selectedChannel, setSelectedChannel] = useState('');  // Fix: Add this
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const convertedLeads = filteredData.filter(item => item.Converted === "True"); // Get all converted leads
  const organicInConvertedCount = convertedLeads.filter(item => item.utmCategory === "Organic").length;
  const paidInConvertedCount = convertedLeads.filter(item => item.utmCategory === "Paid" || (item.utmCategory === "Organic / Paid" && item.Converted === "True")).length;
  const RegisteredCount = filteredData.filter(item => item.Converted === "True").length;
  // Calculate the total Deposit sum for Converted leads
  const totalDepositConverted = convertedLeads.reduce((sum, item) => sum + (item.Deposit || 0), 0);
  // Define colors for statuses
  const colors = ["#DE0D92", "#f28b82", "#fbbc04", "#34a853", "#4285f4", "#a142f4", "#ff6d00"];
  // Unique values for dropdowns
  // Get unique Channels but exclude empty or undefined values
  const uniqueChannels = [...new Set(reportData.map(item => item.Channel).filter(channel => channel && channel.trim() !== ""))];
  const uniqueSource = [...new Set(reportData.map(item => item.Source).filter(Source => Source && Source.trim() !== ""))];
  const uniqueCampaigns = [...new Set(reportData.map(item => item.Campaign))];
  const [cardWidth, setCardWidth] = useState("429px");
  const [cardMaxHeight, setCardMaxHeight] = useState("auto");
  const [cardMarginLeft, setCardMarginLeft] = useState("47px");
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ datasets: [] }); // Ensure data is initialized
  const [gradient, setGradient] = useState(null);


  // Calculate deposits separately for Paid and Organic leads inside Converted
  const paidDepositConverted = convertedLeads
    .filter(item => item.utmCategory === "Paid")
    .reduce((sum, item) => sum + (item.Deposit || 0), 0);

  const organicDepositConverted = convertedLeads
    .filter(item => item.utmCategory === "Organic" || item.utmCategory === "Organic / Paid")
    .reduce((sum, item) => sum + (item.Deposit || 0), 0);

  // Get unique statuses with their counts
  const statusCounts = filteredData.reduce((acc, item) => {
    let status = item.Status;

    // Check for empty, null, or undefined values and replace them with "Undefined"
    if (!status || status.trim() === "" || status === null || status === undefined) {
      status = "Undefined";
    }

    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  // Convert object into an array of { status, count }
  const statusArray = Object.entries(statusCounts).map(([status, count]) => ({
    status,
    count,
  }));

  // Fetch data
  useEffect(() => {
    const fetchMarketReport = async () => {
      try {
        const response = await MarketReport.fetchMarketReport();
        if (Array.isArray(response)) {
          setReportData(response);
          setFilteredData(response); // Initial load
        } else {
          console.warn("Unexpected API response:", response);
        }
      } catch (error) {
        console.error("Error fetching market report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMarketReport();
  }, []);

  useEffect(() => {
    let data = [...reportData];

    // Convert date range inputs to full day format
    if (dateRange.start && dateRange.end) {
      const startDate = new Date(dateRange.start);
      startDate.setHours(0, 0, 0, 0); // Set to 00:00:00

      const endDate = new Date(dateRange.end);
      endDate.setHours(23, 59, 59, 999); // Set to 23:59:59

      data = data.filter(item => {
        const createdDate = new Date(item.Created);
        return createdDate >= startDate && createdDate <= endDate;
      });
    }

    // Filter by Channel (Platform)
    if (selectedChannel) {
      data = data.filter(item => item.Channel === selectedChannel);
    }
    if (selectedSource) {
      data = data.filter(item => item.Source === selectedSource);
    }

    // Filter by Campaign
    if (selectedCampaign) {
      data = data.filter(item => item.Campaign === selectedCampaign);
    }

    setFilteredData(data);
  }, [dateRange, selectedChannel, selectedSource, selectedCampaign, reportData]);


  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth <= 1280) {
        setCardWidth("364px");
        setCardMaxHeight("auto");
        setCardMarginLeft("0px"); // Remove margin-left on smaller screens
      } else {
        setCardWidth("429px");
        setCardMaxHeight("377px");
        setCardMarginLeft("47px"); // 
      }
    };

    window.addEventListener("resize", updateSize);
    updateSize(); // Initial check

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (chartRef.current && chartRef.current.canvas) {
      const ctx = chartRef.current.canvas.getContext("2d");

      // Create a gradient for Converted
      const gradientFill = ctx.createLinearGradient(0, 0, 200, 200);
      gradientFill.addColorStop(0, "#06E766"); // Light Green
      gradientFill.addColorStop(1, "#27E7B1"); // Dark Green

      setGradient(gradientFill);
    }
  }, []);

  useEffect(() => {
    if (filteredData.length > 0) {
      // Count Converted and Other Leads
      const convertedCount = filteredData.filter(item => item.Converted === "True").length;
      const otherLeadsCount = filteredData.filter(item => item.Converted === "False").length;

      setChartData({
        labels: ["Converted", "Other Leads"],
        datasets: [
          {
            data: [convertedCount, otherLeadsCount],
            backgroundColor: [gradient || "#06E766", "#D3D3D3"], // Use gradient or fallback color
            hoverBackgroundColor: [gradient || "#27E7B1", "#a9a9a9"],
          },
        ],
      });
    }
  }, [filteredData, gradient]); // Runs whenever filteredData or gradient changes

  // Chart Options
  const chartOptions = {
    cutout: "70%", // Creates a donut effect
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  };

  useEffect(() => {
    if (filteredData.length > 0) {
      const monthlyData = {};

      // Filter only converted clients
      const convertedClients = filteredData.filter(item => item.Converted === "True");

      convertedClients.forEach((item) => {
        if (!item.Created) return;

        const date = new Date(item.Created);
        const monthKey = `${date.toLocaleString("default", { month: "short" })}-${date.getFullYear().toString().slice(-2)}`; // Format: Jan-24

        if (!monthlyData[monthKey]) {
          monthlyData[monthKey] = { clients: 0, deposit: 0 };
        }

        monthlyData[monthKey].clients += 1; // Count only converted clients
        monthlyData[monthKey].deposit += item.Deposit || 0; // Sum deposit
      });

      // Sort the data by date order
      const sortedKeys = Object.keys(monthlyData).sort((a, b) => {
        const [monthA, yearA] = a.split("-");
        const [monthB, yearB] = b.split("-");
        return new Date(`${monthA} 1, 20${yearA}`) - new Date(`${monthB} 1, 20${yearB}`);
      });

      const labels = sortedKeys;
      const clientData = sortedKeys.map((key) => monthlyData[key].clients);
      const depositData = sortedKeys.map((key) => monthlyData[key].deposit);

      setLineChartData({
        labels,
        datasets: [
          {
            label: "Clients",
            data: clientData,
            borderColor: "#16A34A",
            backgroundColor: "rgba(22, 163, 74, 0.2)",
            tension: 0.4,
          },
          {
            label: "Deposits",
            data: depositData,
            borderColor: "#EF4444",
            backgroundColor: "rgba(239, 68, 68, 0.2)",
            tension: 0.4,
          },
        ],
      });
    }
  }, [filteredData]);

  // Line Chart Options
  const lineChartOptions = {
    plugins: { legend: { display: false } },
    scales: {
      x: { display: false },
      y: { display: false },
    },
  };

  const agents = [
    { name: "Lily French", role: "Director Direct Sales", image: Image1 },
    { name: "Howard Adkins", role: "Director Direct Sales", image: Image2 },
    { name: "Earl Bowman", role: "Director Direct Sales", image: Image3 },
    { name: "Patrick Padilla", role: "Director Direct Sales", image: Image4 },
    { name: "Lily French", role: "Director Direct Sales", image: Image1 },

  ];

  // Function to Fetch Image using getUrl
  const getUrl = async (pictureId) => {
    try {
      const res = await getDocumentSas(pictureId);
      // console.log("Response from getDocumentSas:", res);

      if (res && res.url) {
        return res.url; // Return the URL to be used for the image
      } else {
        return Image1; // Use local fallback image
      }
    } catch (err) {
      console.error("Error fetching image:", err);
      return Image1; // Return local fallback image on failure
    }
  };

  // Fetch and Group Data by SalesId
  useEffect(() => {
    const fetchAndGroupSalesData = async () => {
      try {
        const response = await MarketReport.fetchMarketReport();

        let salesData;
        if (Array.isArray(response)) {
          salesData = response; // API returned an array directly
        } else if (response.status && Array.isArray(response.result)) {
          salesData = response.result; // API returned expected {status, result}
        } else {
          console.warn("Unexpected API response format:", response);
          return;
        }

        const salesGroup = {};

        for (const lead of salesData) {
          const { SalesId, SalesManager, SalesJobTitle, pictureId } = lead;

          if (!salesGroup[SalesId]) {
            salesGroup[SalesId] = {
              SalesManager,
              SalesJobTitle,
              pictureId,
              totalLeads: 0,
            };
          }

          salesGroup[SalesId].totalLeads += 1;
        }

        setGroupedSalesData(salesGroup);
      } catch (error) {
        console.error("Error fetching market report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndGroupSalesData();
  }, []);

  // Fetch Profile Images for Each pictureId
  useEffect(() => {
    const fetchAgentImages = async () => {
      const imageUrls = {};

      await Promise.all(
        Object.values(groupedSalesData).map(async (agent) => {
          const url = await getUrl(agent.pictureId); // Use getUrl() to fetch image
          imageUrls[agent.pictureId] = url;
        })
      );

      setAgentImages(imageUrls);
    };

    if (Object.keys(groupedSalesData).length > 0) {
      fetchAgentImages();
    }
  }, [groupedSalesData]);



  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <style>{`.form-group.w-75 { display: none; } .form-inline.search-fulls.col-7 { border: none; } .MuiBox-root.css-19midj6 { padding: 3px; } .border-left { border-left-width: 4px; }`}</style>
      {/* Adding the new div before the SalesReports content */}
      <div style={{ height: "4px" }} className="container-fluid">
        <div className="page-title" style={{ display: "none" }}>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mb-6">
        <h4 style={{ color: 'black' }} className="text-2xl font-bold">Overview</h4>

        <div className="flex items-center gap-2" style={{ width: '65%' }}>
          {/* Platform (Source) Dropdown */}
          <Input
            type="select"
            className="border rounded px-3 py-2 w-48"
            value={selectedSource}
            onChange={(e) => setSelectedSource(e.target.value)}
          >
            <option value="">All Source</option>
            {uniqueSource.map(Source => (
              <option key={Source} value={Source}>{Source}</option>
            ))}
          </Input>
          {/* Platform (Channel) Dropdown */}
          <Input
            type="select"
            className="border rounded px-3 py-2 w-48"
            value={selectedChannel}
            onChange={(e) => setSelectedChannel(e.target.value)}
          >
            <option value="">All Platforms</option>
            {uniqueChannels.map(channel => (
              <option key={channel} value={channel}>{channel}</option>
            ))}
          </Input>

          {/* Campaign Dropdown */}
          <Input
            type="select"
            className="border rounded px-3 py-2 w-48"
            value={selectedCampaign}
            onChange={(e) => setSelectedCampaign(e.target.value)}
          >
            <option value="">All Campaigns</option>
            {uniqueCampaigns.map(campaign => (
              <option key={campaign} value={campaign}>{campaign}</option>
            ))}
          </Input>

          {/* Date Range Filters */}
          <Input
            type="date"
            className="border rounded px-3 py-2 w-48" style={{ height: "41.6px" }}
            onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
          />
          <Input
            type="date"
            className="border rounded px-3 py-2 w-48" style={{ height: "41.6px" }}
            onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
          />
        </div>
      </div>

      {/* Main Card - Left Side Split, Right Side Full */}
      <Card className="p-6 mb-6 flex flex-row justify-between items-center" style={{ marginTop: "20px", height: "232px", borderRadius: "24px", marginBottom: "20px" }}>

        {/* Left Side - Split into Two Sections */}
        <div className="w-1/2 flex flex-col justify-between p-4">

          {/* Top Left - Header */}
          <div className="flex items-center gap-4" style={{ width: "271px", height: "79px", position: "absolute" }}>
            <Media left>
              <img src={Accounts} alt="Accounts" className="w-10" />
            </Media>
            <Media body>
              <h2 className="text-lg font-semibold" style={{ marginBottom: "-5px", color: "black" }}>
                {loading ? "Loading..." : filteredData.length}
              </h2>
              <p className="text-gray-500">Total Leads</p>
            </Media>
          </div>

          <div className="flex flex-wrap gap-2  overflow-auto max-h-40 md:max-h-52 lg:max-h-full"
            style={{ height: "auto", marginTop: "60px" }}>
            {statusArray.map((item, index) => {
              const color = colors[index % colors.length]; // Cycle through colors

              return (
                <button key={item.status} className="px-4 py-2 text-sm font-semibold rounded-lg"
                  style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "auto", minWidth: "100px", maxWidth: "fit-content", height: "34px", padding: "0 12px", border: `1px solid ${color}`, color, backgroundColor: `${color}1A`, whiteSpace: "nowrap", overflow: "hidden" }}>
                  {`${item.status} (${item.count})`}
                </button>
              );
            })}
          </div>
        </div>
        {/* Right Side - Full Chart */}
        <div className="w-1/2 flex justify-center items-center" >
          <div style={{ width: "192px", height: "192px", marginTop: "26px", marginRight: "16px" }}>
            <Doughnut data={chartData} options={chartOptions} />
          </div>
        </div>
      </Card>

      {/* Left Section: Registered Clients & Deposit Amount */}
      <div className="bg-white shadow-md rounded-2xl p-6 w-full flex ">

        {/* Flex Container to Keep Both Cards Side by Side */}
        <Row className="flex w-full gap-6 justify-center items-center" style={{ width: '100%', alignItems: 'stretch' }}>

          {/* Left Section: Two Cards Side by Side */}
          <Col md={8} className="d-flex justify-content-center">
            <Card className="p-4 text-center flex-grow-1 h-100 d-flex align-items-center justify-content-center"
              style={{ maxHeight: '251px', overflow: 'hidden', borderRadius: '24px' }}>
              <CardBody className="d-flex w-100 h-100 justify-content-center align-items-center">

                {/* Flex Container for the Two Cards */}
                <div className="d-flex w-100 h-100 gap-4 justify-content-center align-items-center">

                  {/* First Card */}
                  <div className="card p-4 mb-0" style={{ width: cardWidth, height: "203px", borderRadius: "10px", backgroundColor: "#EBF5FF" }}>
                    {/* Registered Clients Title */}
                    {/* Vertical Line */}
                    <div style={{ width: "2px", height: "73px", backgroundColor: "#007bff", position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)", margin: "0" }}></div>

                    <div className="flex justify-between mt-2 text-sm text-gray-600">
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Registered Clients</span>
                        <br />
                        <strong className="text-gray-900" style={{ float: "left", fontSize: "24px" }}>{RegisteredCount}</strong>
                      </div>
                      <div className="relative">
                        <div className="absolute top-0 right-0 bg-blue-100 p-2 rounded-full">
                          <img
                            src={BluewalletIcon}
                            alt="Blue Wallet Icon"
                            className="w-6 h-6"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Paid and Organic Counts */}

                    <div className="flex justify-between mt-4 text-sm text-gray-600">
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Paid</span>
                        <br />
                        <strong className="text-gray-900" style={{ float: "left" }}>{paidInConvertedCount}</strong>
                      </div>
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Organic</span>
                        <br />
                        <strong className="text-gray-900" style={{ float: "left" }}>{organicInConvertedCount}</strong>
                      </div>
                    </div>
                  </div>


                  {/* Second Card */}

                  <div className="card p-4 mb-0" style={{ width: cardWidth, height: '203px', borderRadius: '10px', backgroundColor: "#F4EDFD", marginLeft: cardMarginLeft, }}>
                    {/* Vertical Line */}
                    <div style={{ width: "2px", height: "73px", backgroundColor: "#BD59F9", position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)", margin: "0" }}></div>
                    {/* Registered Clients Title */}
                    <div className="flex justify-between mt-2 text-sm text-gray-600">
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Deposit Amount</span>
                        <br />
                        <strong className="text-gray-900" style={{ float: "left", fontSize: "24px" }}>${totalDepositConverted.toFixed(2)}</strong>
                      </div>
                      <div className="relative">
                        <div className="absolute top-0 right-0 bg-blue-100 p-2 rounded-full">
                          <img
                            src={PinkwalletIcon}
                            alt="Pink Wallet Icon"
                            className="w-6 h-6"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Paid and Organic Counts */}
                    <div className="flex justify-between mt-4 text-sm text-gray-600">
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Paid</span>
                        <br />
                        <strong className="text-gray-900" style={{ float: "left" }}>${paidDepositConverted.toFixed(2)}</strong>
                      </div>
                      <div>
                        <span style={{ color: "#9F9F9F" }}>Organic</span>
                        <br />
                        <strong className="text-gray-900" style={{ float: "left" }}>${organicDepositConverted.toFixed(2)}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>


          {/* Chart Section */}
          <Col md={4} className="d-flex position-relative">
            <div
              className="card flex-grow-1 d-flex h-100 position-relative"
              style={{ maxHeight: "251px", borderRadius: "24px", marginTop: "20px" }}
            >
              {/* Labels with Absolute Position */}
              <div
                className="d-flex"
                style={{
                  position: "absolute",
                  top: window.innerWidth >= 768 ? "30px" : "0px",
                  left: "20px", // Adjust left alignment if needed
                  zIndex: 10, // Ensures labels are above the chart
                }}
              >
                <span className="badge bg-success me-2">CLIENTS</span>
                <span className="badge bg-danger">DEPOSITS</span>
              </div>

              {/* Chart Container */}
              <div
                className="card-body d-flex align-items-center justify-content-center h-100"
                style={{ maxHeight: "251px", overflow: "hidden" }}
              >
                <Line data={lineChartData} options={lineChartOptions} />
              </div>
            </div>
          </Col>
        </Row>
      </div>


      <Card className="p-6 mb-6" style={{ marginTop: "20px", borderRadius: "24px", marginBottom: "30px", maxHeight: "419px" }}>
        <div style={{ padding: "26px" }}>
          {/* Header Section */}
          <div className="flex justify-between items-center" >
            <h4 className="text-lg font-semibold text-black">Sales</h4>
            <a href="/MarketSales" className="text-blue-500 text-sm">View All</a>
          </div>

          {loading ? (
            <p>Loading sales data...</p>
          ) : Object.keys(groupedSalesData).length === 0 ? (
            <p>No sales data available.</p>
          ) : (
            <div className="flex flex-wrap gap-4">
              {Object.values(groupedSalesData).map((data) => (
                <Card

                  className="p-4 text-center shadow-md bg-gray-100 rounded-14 flex-shrink-0"
                  style={{
                    background: "#F7F8F9",
                    width: "calc(100% / 4 - 16px)",  // Ensures even distribution
                    maxWidth: "226px",
                    minWidth: "180px",
                    borderRadius: "14px",

                  }}
                >
                  <CardBody>
                    <img
                      src={agentImages[data.pictureId] || Image1} // Display fetched image or local fallback
                      alt={data.SalesManager}
                      className="w-16 h-16 rounded-full mx-auto mb-2 object-cover"
                      style={{
                        width: "87.1px",
                        height: "87.1px",
                        borderRadius: "50%",
                        border: "3px solid #ddd",
                        objectFit: "cover",
                      }}
                    />
                    <p style={{ fontSize: "15px", color: "#2C323F", marginBottom: "0px" }}>{data.SalesManager}</p>
                    <p style={{ fontSize: "10.56px", color: "#979797" }}>{data.SalesJobTitle}</p>
                    <button
                      onClick={() => navigate('/MarketSales', { state: { selectedAgent: data } })}
                      style={{
                        background: "#E6E6E6",
                        color: "#898989",
                        border: "none",
                        height: "23.82px",
                        width: "103.84px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      View Profile
                    </button>
                  </CardBody>
                </Card>
              ))}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Dashboard;



