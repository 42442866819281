import api from './api';

export const fetchshifts = () => {
    return api.get(`/v1/crm/shifts`);
};

export const createshifts = (data) => {
    console.log('Created Shifts',data);
    return api.post(`/v1/crm/shifts`, data);
};

export const updateshifts= (id, data) => {
    return api.put(`/v1/crm/shifts/${id}`, data);
};

export const deleteshifts = (id) => {
    return api.delete(`/v1/crm/shifts/${id}`);
};
    