import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const getTransactions = (params) => {
  return api.get("/v1/crm/transactions?" + queryString(params));
};

export const getCountryTransactions = (params) => {
  console.log("Params ", params);
  return api.get("/v1/crm/transactions/getCountryTransactions?" + queryString(params));
};

export const getTransactionDetails = (params) => {
  return api.get("/v1/crm/transactions/transactionDetails?" + queryString(params));
};
export const getTransactionDetailsWithDrawals = (params) => {
  return api.get("/v1/crm/transactions/getTransactionDetailsWithDrawals?" + queryString(params));
};
export const postDeposit = (params) => {
  return api.post("/v1/crm/transactions/deposit", params);
};
export const postTransaction = (type, params) => {
  return api.post("/v1/crm/transactions/" + type, params);
};
export const postWithdrawals = (params) => {
  return api.post("/v1/crm/transactions/withdrawal", params);
};

export const postInterTransfer = (params) => {
  return api.post("/v1/crm/transactions/internal-transfer", params);
};
export const postTransactionApproved = (params, id) => {
  return api.post("/v1/crm/transactions/" + id + "/approve", params);
};
export const postTransactionReject = (params, id) => {
  return api.post("/v1/crm/transactions/" + id + "/reject", params);
};


export const getTransactionDepositValue = (params) => {
  return api.get("/v1/crm/transactions/depositeValue?" + queryString(params));
};

// New API Service for Updating Deposit Fields
export const patchDeposit = (id, params) => {
  return api.patch(`/v1/crm/transactions/${id}/deposit`, params);
};

export const deleteTransactionDocs = (id, params) => {
  return api.delete(`/v1/crm/transactions/${id}/docs`, { data: params });
};


export const postRejectMultipleTransactions = (params) => {
  return api.post("/v1/crm/transactions/reject-multiple", params);
};
