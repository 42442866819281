import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const DeleteConfirmationModal = ({ modal, toggle, field, rowId, onConfirmDelete }) => {
  const getFieldLabel = (field) => {
    // Determine the label based on the field value
    if (field === "doc1" || field === "doc2") {
      return "Document";
    }
    if (field === "doc3" || field === "doc4") {
      return "Receipt Attachment";
    }
    return field; // Default to the field name if it doesn't match any condition
  };

  const handleConfirm = () => {
    onConfirmDelete(field, rowId); // Pass field and rowId to the parent
    toggle(); // Close the modal after confirmation
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
      <ModalBody>
        Are you sure you want to delete <strong>{getFieldLabel(field)}</strong> for Row ID <strong>{rowId}</strong>?
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleConfirm}>
          Delete
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;
