import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Table,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Form,
  FormGroup,
  Spinner,
} from 'reactstrap';
import { ArrowLeft, Edit, Trash, PlusCircle, Eye, Upload } from "react-feather";



import { fetchEmplocation, createEmplocation, updateEmplocation, deleteEmplocation } from 'Services/emplocation.service';
import { fetchshifts, createshifts, updateshifts, deleteshifts } from 'Services/shifts.service';
import { fetchAttendanceRecords, deleteAttendance } from "Services/attendance.service";
import { getUserOptions } from 'Services/user.service';
import { UsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import classnames from 'classnames';


const Attendance = () => {
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || '1');
  const [shiftsModal, setShiftsModal] = useState(false);
  const [sheetModal, setSheetModal] = useState(false);
  const [locationModal, setlocationModal] = useState(false);
  const [recordsModal, setrecordsModal] = useState(false);
  const [recommendModal, setrecommendModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [selectedrecords, setSelectedrecords] = useState(null);
  const [selectedrecommend, setSelectedrecommend] = useState(null);
  const [shiftSearch, setShiftSearch] = useState('');
  const [sheetSearch, setSheetSearch] = useState('');
  const [locationSearch, setlocationSearch] = useState('');
  const [recordsSearch, setrecordsSearch] = useState('');
  const [recommendSearch, setrecommendSearch] = useState('');
  const [attendanceSearch, setAttendanceSearch] = useState("");
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [usersPersonalDetails, setUsersPersonalDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userOptions, setUserOptions] = useState([]); // Store user options




  // Toggle between tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [shifts, setshifts] = useState([]);
  const [Shiftsformvalues, setShiftsformvalues] = useState([
    {

      emp_name: '',
      emp_no: '',
      shift: '',
      emp_code: '',
      hours: '',
      fromDate: '',
      toDate: '',
    },
    // Add more data here
  ]);
  const [isShiftsEditMode, setIsShiftsEditMode] = useState(false);


  const [A_sheetsData, setA_sheetsData] = useState([
    {
      id: 1,
      sheetName: 'attendance_4875_20240913102133972786473.csv',
      fromDate: 'Thu, 2022-09-01',
      toDate: 'Fri, 2022-09-30',
      successes: '8',
      failures: '0',
      status: 'Success',
      importedOn: '2024-09-13 11:21 am'
    },
    // Add more data here
  ]);
  const [locations, setlocation] = useState([]);

  const [locationsFormValues, setlocationsFormValues] = useState({
    emp_name: '',
    emp_no: '',
    geolocation: '',
  });

  const [islocationsEditMode, setIslocationsEditMode] = useState(false);


  const [recordsData, setrecordsData] = useState([
    {
      id: 1,
      emp_name: 'Atul Madaan',
      emp_no: '101009',
      location: 'Dubai Office',
      cardId: '123450',
      shift: 'OPERATIONS SHIFT',
      date: '09-10-2024',
      firstIn: '10:00 AM',
      lastOut: '7:00 PM',
      extraInfo: 'TEST',

    }
  ]);
  const [recommendData, setrecommendsData] = useState([
    {
      id: 1,
      emp_name: 'Atul Madaan',
      emp_no: '101009',
      date: '09-10-2024',
      entrytime: 'Fri, 11:00 AM ',
      exittime: 'Fri, 12:16 AM',
      t_value: '1 hour and 16 minutes',
      t_type: '	Morning Lateness',

    }
  ]);
  // Fetch Employee Details

  const loadUserOptions = async () => {
    try {
      const response = await getUserOptions();
      console.log('API Response:', response);

      if (!response || !response.data || !Array.isArray(response.data.data)) {
        console.error('No valid data in response:', response);
        return;
      }

      const userDataArray = response.data.data;
      console.log('User Data Array:', userDataArray);

      const formattedOptions = userDataArray.map(user => {
        if (user.id && user.firstName && user.lastName) {
          return {
            value: user.id,
            label: `${user.firstName} ${user.lastName}`
          };
        } else {
          console.error('User object is missing properties:', user);
          return null;
        }
      }).filter(option => option !== null);

      console.log('Formatted Options:', formattedOptions);
      setUserOptions(formattedOptions);
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  useEffect(() => {
    loadUserOptions(); // Fetch users on component load
  }, []);

  useEffect(() => {
    const loadUsersPersonalDetails = async () => {
      try {
        const fetchedUsers = await UsersPersonalDetail();
        setUsersPersonalDetails(fetchedUsers?.rows || fetchedUsers || []);
      } catch (error) {
        console.error('Error fetching employee details:', error);
      }
    };

    const loadAttendanceData = async () => {
      try {
        const fetchedAttendance = await fetchAttendanceRecords();
        if (fetchedAttendance && Array.isArray(fetchedAttendance.rows)) {
          setAttendanceRecords(fetchedAttendance.rows);
        } else {
          console.error("Expected an array but got:", fetchedAttendance);
        }
      } catch (error) {
        console.error('Error fetching attendance records:', error);
      } finally {
        setLoading(false);
      }
    };

    loadUsersPersonalDetails();
    loadAttendanceData();
  }, []);
  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  // Process attendance records: Group by emp_code, find First In & Last Out
  const filteredAttendance = attendanceRecords.reduce((acc, record) => {
    const recordDate = new Date(record.punch_time).toISOString().split("T")[0];

    if (recordDate >= startDate && recordDate <= endDate) {
      // Find the corresponding employee in Users_personal where section matches emp_code
      const employee = usersPersonalDetails.find((user) => user.section === record.emp_code);
      const emp_name = employee ? employee.name : "Unknown"; // Default to "Unknown" if not found

      const existing = acc.find((r) => r.emp_code === record.emp_code);

      if (!existing) {
        acc.push({
          emp_code: record.emp_code,
          emp_name: emp_name, // Store matched name
          date: recordDate,
          firstIn: record.punch_time,
          lastOut: record.punch_time,
        });
      } else {
        if (new Date(record.punch_time) < new Date(existing.firstIn)) {
          existing.firstIn = record.punch_time;
        }
        if (new Date(record.punch_time) > new Date(existing.lastOut)) {
          existing.lastOut = record.punch_time;
        }
      }
    }
    return acc;
  }, []);
  const getAttendanceStatus = () => {
    const dateArray = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    while (start <= end) {
      dateArray.push(new Date(start).toISOString().split("T")[0]);
      start.setDate(start.getDate() + 1);
    }
  
    return usersPersonalDetails.flatMap((user) => {
      return dateArray.map((date) => {
        const records = attendanceRecords.filter(
          (record) =>
            record.emp_code === user.section &&
            new Date(record.punch_time).toISOString().split("T")[0] === date
        );
  
        return {
          emp_name: user.name,
          emp_no: user.section,
          date,
          status: records.length > 0 ? "Present" : "Absent",
          times: records.length > 0 ? records.map(r => new Date(new Date(r.punch_time).getTime() - (4 * 60 * 60 * 1000)).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })).join(", ") : "N/A",
        };
      });
    });
  };
  
  const getDailyPunchRecords = () => {
    const dateArray = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    while (start <= end) {
      dateArray.push(new Date(start).toISOString().split("T")[0]);
      start.setDate(start.getDate() + 1);
    }
  
    return usersPersonalDetails.flatMap((user) => {
      return dateArray.map((date) => {
        const records = attendanceRecords.filter(
          (record) =>
            record.emp_code === user.section &&
            new Date(record.punch_time).toISOString().split("T")[0] === date
        );
  
        const firstIn = records.length > 0 ? records.reduce((earliest, r) => new Date(r.punch_time) < new Date(earliest) ? r.punch_time : earliest, records[0].punch_time) : "N/A";
        const lastOut = records.length > 0 ? records.reduce((latest, r) => new Date(r.punch_time) > new Date(latest) ? r.punch_time : latest, records[0].punch_time) : "N/A";
  
        return {
          emp_name: user.name,
          emp_no: user.section,
          date,
          firstIn: firstIn !== "N/A" ? new Date(new Date(firstIn).getTime() - (4 * 60 * 60 * 1000)).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "N/A",
          lastOut: lastOut !== "N/A" ? new Date(new Date(lastOut).getTime() - (4 * 60 * 60 * 1000)).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "N/A",
        };
      });
    });
  };
  
  const employeeAttendanceStatus = getAttendanceStatus();
  const dailyPunchRecords = getDailyPunchRecords();
  
  // Handle date selection
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  // Delete attendance record
  const handleDeleteAttendance = async (id) => {
    try {
      await deleteAttendance(id);
      toast.success("Attendance record deleted successfully!");
      setAttendanceRecords((prevRecords) =>
        prevRecords.filter((record) => record.id !== id)
      );
    } catch (error) {
      console.error("Error deleting attendance record:", error);
      toast.error("Failed to delete attendance record.");
    }
  };

  // Load Shifts
  const loadshiftsData = async () => {
    try {
      const fetchedshifts = await fetchshifts();
      console.log('Fetched Shifts:', fetchedshifts); // Debugging statement

      if (fetchedshifts && Array.isArray(fetchedshifts.rows)) {
        setshifts(fetchedshifts.rows);
      } else {
        console.error('Expected an array in rows, but got:', fetchedshifts);
      }
    } catch (error) {
      console.error('Error fetching and filtering shifts:', error);
    }
  };
  useEffect(() => {
    loadshiftsData();
  }, []);


  // Load Locations
  const loadlocationsData = async () => {
    try {
      const fetchedlocations = await fetchEmplocation();
      console.log('Fetched Locations:', fetchedlocations); // Debugging statement

      if (fetchedlocations && Array.isArray(fetchedlocations.rows)) {
        setlocation(fetchedlocations.rows);
      } else {
        console.error('Expected an array in rows, but got:', fetchedlocations);
      }
    } catch (error) {
      console.error('Error fetching and filtering locations:', error);
    }
  };

  useEffect(() => {
    loadlocationsData();
  }, []);


  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);


  // Toggle modal for shifts
  const toggleShiftModal = () => setShiftsModal(!shiftsModal);
  // Toggle modal for sheets
  const toggleSheetModal = (sheet = null) => {
    setSelectedSheet(sheet);
    setSheetModal(!sheetModal);
  };

  //Toggle modal for location
  const togglelocationModal = () => setlocationModal(!locationModal); // Add this line
  //Toggle modal for records
  const togglerecordsModal = (records = null) => {
    setSelectedrecords(records);
    setrecordsModal(!recordsModal);
  };
  //Toggle modal for reccommend
  const togglerecommendModal = (recommend = null) => {
    setSelectedrecommend(recommend);
    setrecommendModal(!recommendModal);
  };


  // Shifts Info Handlers
  // Handles form input changes for shifts
  const handleShiftsFormChange = (e, id) => {
    const { name, value } = e.target;

    setShiftsformvalues(prevValues =>
      prevValues.map((shift, index) => {
        if (index !== id) return shift;

        // If changing the employee name, auto-fill the employee number
        if (name === "emp_name") {
          const selectedEmployee = userOptions.find(emp => emp.label === value);
          return {
            ...shift,
            emp_name: value,
            emp_no: selectedEmployee ? selectedEmployee.value : "",
          };
        }

        // If changing the shift, auto-fill the shift hours
        return {
          ...shift,
          [name]: value,
          hours: name === "shift" ? getShiftHours(value) : shift.hours,
        };
      })
    );
  };

  // Auto-fill employee number on selection
  const handleEmployeeSelect = (e, index) => {
    const { value } = e.target;
    const selectedUser = userOptions.find(user => user.value === value); // Get selected employee data

    const updatedShifts = [...Shiftsformvalues];
    updatedShifts[index] = {
      ...updatedShifts[index],
      emp_no: selectedUser?.value || '', // Store employee number
    };

    setShiftsformvalues(updatedShifts); // Update state
  };



  // Function to get shift hours based on shift type
  const getShiftHours = (shiftType) => {
    const shiftHours = {
      'OPERATIONS SHIFT': '10:00AM TO 7:00PM',
      '10.30AM SHIFT': '10:30AM TO 7:30PM',
      '11AM SHIFT': '11:00AM TO 8:00PM',
      '9.30AM SHIFT': '9:30AM TO 6:30PM',
      '8AM SHIFT': '8:00AM TO 5:00PM',
      '9AM SHIFT': '9:00AM TO 6:00PM',
      'Dealing 1 SHIFT': '1:00PM TO 10:00PM',
      'Night SHIFT': '10:00PM TO 7:00AM',
      'Morning SHIFT': '7:00AM TO 4:00PM'
    };
    return shiftHours[shiftType] || '';
  };

  // Initialize form for creating a new shift
  const initializeCreateShiftForm = () => {
    setIsShiftsEditMode(false);
    setShiftsformvalues([{
      emp_name: '',
      emp_no: '',
      shift: '',
      hours: '',
      emp_code: '',
      fromDate: '',
      toDate: '',
    }]);
    toggleShiftModal();
  };

  // Initialize form for updating an existing shift
  const initializeUpdateShiftForm = (shift) => {
    setShiftsformvalues([shift]);
    setIsShiftsEditMode(true);
    toggleShiftModal();
  };

  // Delete shift
  const deleteShift = async (id) => {
    try {
      await deleteshifts(id);
      toast.success('Shift deleted successfully!');
      setshifts(prev => prev.filter(shift => shift.id !== id));
    } catch (error) {
      console.error('Error deleting shift:', error);
      toast.error('Failed to delete shift.');
    }
  };

  // Save shift (create or update)
  const handleSaveShift = async (e) => {
    e.preventDefault();

    console.log("Shift Data to be Saved:", Shiftsformvalues[0]); // Debugging log

    try {
      const shiftData = {
        emp_name: Shiftsformvalues[0].emp_name, // Should not be empty
        emp_no: Shiftsformvalues[0].emp_no,
        shift: Shiftsformvalues[0].shift,
        hours: Shiftsformvalues[0].hours,
        emp_code: Shiftsformvalues[0].emp_code,
        fromDate: Shiftsformvalues[0].fromDate,
        toDate: Shiftsformvalues[0].toDate,
      };

      if (!shiftData.emp_name) {
        console.error("🚨 emp_name is empty before submission!", shiftData);
        toast.error("Please select an employee name.");
        return;
      }

      if (isShiftsEditMode) {
        await updateshifts(Shiftsformvalues[0].id, shiftData);
        toast.success("Shift updated successfully!");
      } else {
        await createshifts(shiftData);
        toast.success("Shift added successfully!");
      }

      await loadshiftsData();
      toggleShiftModal();
    } catch (error) {
      console.error("Error saving shift:", error);
      toast.error("Failed to save shift.");
    }
  };

  // Handles searching for shifts
  const handleSearchShiftChange = (e) => {
    setShiftSearch(e.target.value); // Update search query
  };

  // Location Info Handlers
  // Handles form input changes
  const handleLocationFormChange = (e) => {
    const { name, value } = e.target;
    setlocationsFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Initializes the form for creating a new location
  const initializeCreateLocationForm = () => {
    setIslocationsEditMode(false);
    setlocationsFormValues({
      emp_name: '',
      emp_no: '',
      geolocation: ''
    });
    togglelocationModal(true); // Opens the modal
  };

  // Initializes the form for updating an existing location
  const initializeUpdateLocationForm = (location) => {
    setIslocationsEditMode(true);
    setlocationsFormValues(location);
    togglelocationModal(true); // Opens the modal
  };

  // Deletes a user location by ID
  const deleteLocation = async (id) => {
    try {
      await deleteEmplocation(id); // Assuming this is the API call to delete the location
      toast.success('Location deleted successfully!');
      setlocation(prevDetails => prevDetails.filter(location => location.id !== id));
    } catch (error) {
      console.error('Error deleting location:', error);
      toast.error('Failed to delete location. Please try again.');
    }
  };

  // Handles saving the location (create or update)
  const handleSaveLocation = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!locationsFormValues.emp_name || !locationsFormValues.geolocation) {
      toast.error('Please fill in all required fields.');
      return;
    }

    const payload = {
      ...locationsFormValues,
      emp_no: locationsFormValues.emp_no
    };

    try {
      if (islocationsEditMode) {
        // Call the actual update API function
        await updateEmplocation(locationsFormValues.id, payload);
        toast.success('Location updated successfully!');
      } else {
        // Call the actual create API function
        await createEmplocation(payload);
        toast.success('Location added successfully!');
      }

      // Refresh the locations list after saving
      await loadlocationsData();

      // Close the location modal after saving
      togglelocationModal();
    } catch (error) {
      console.error('Error saving location:', error);
      toast.error('Failed to save location. Please try again.');
    }
  };
  const handleSearchlocationChange = (e) => {
    setlocationSearch(e.target.value); // Update search query
  };



  const filteredshifts = shifts.filter((shift) =>
    shift.emp_name.toLowerCase().includes(shiftSearch.toLowerCase()) ||
    shift.emp_no.toLowerCase().includes(shiftSearch.toLowerCase()) ||
    shift.shift.toLowerCase().includes(shiftSearch.toLowerCase())
  );
  const filteredlocations = locations.filter((location) =>
    location.emp_name.toLowerCase().includes(locationSearch.toLowerCase()) ||
    location.emp_no.toLowerCase().includes(locationSearch.toLowerCase()) ||
    location.geolocation.toLowerCase().includes(locationSearch.toLowerCase())
  );
  // Filter sheets data based on search query
  const filteredSheets = A_sheetsData.filter((sheet) =>
    sheet.sheetName.toLowerCase().includes(sheetSearch.toLowerCase()) ||
    sheet.status.toLowerCase().includes(sheetSearch.toLowerCase())
  );

  // Filter records data based on search query
  const filteredRecords = recordsData.filter((record) =>
    record.emp_name.toLowerCase().includes(recordsSearch.toLowerCase()) ||
    record.emp_no.toLowerCase().includes(recordsSearch.toLowerCase()) ||
    record.location.toLowerCase().includes(recordsSearch.toLowerCase()) ||
    record.shift.toLowerCase().includes(recordsSearch.toLowerCase()) ||
    record.date.toLowerCase().includes(recordsSearch.toLowerCase()) ||
    record.firstIn.toLowerCase().includes(recordsSearch.toLowerCase()) ||
    record.lastOut.toLowerCase().includes(recordsSearch.toLowerCase()) ||
    record.extraInfo.toLowerCase().includes(recordsSearch.toLowerCase())
  );
  const filteredAttendanceStatus = employeeAttendanceStatus.filter(
    (record) =>
      record.emp_name.toLowerCase().includes(attendanceSearch.toLowerCase()) ||
      record.emp_no.toLowerCase().includes(attendanceSearch.toLowerCase())
  );


  // Filter recommendations data based on search query
  const filteredRecommendations = recommendData.filter((recommend) =>
    recommend.emp_name.toLowerCase().includes(recommendSearch.toLowerCase()) ||
    recommend.emp_no.toLowerCase().includes(recommendSearch.toLowerCase()) ||
    recommend.date.toLowerCase().includes(recommendSearch.toLowerCase()) ||
    recommend.entrytime.toLowerCase().includes(recommendSearch.toLowerCase()) ||
    recommend.exittime.toLowerCase().includes(recommendSearch.toLowerCase()) ||
    recommend.t_value.toLowerCase().includes(recommendSearch.toLowerCase()) ||
    recommend.t_type.toLowerCase().includes(recommendSearch.toLowerCase())
  );

  // Render shift modal
  const renderShiftModal = () => {
    return (
      <Modal isOpen={shiftsModal} toggle={toggleShiftModal}>
        <ModalHeader toggle={toggleShiftModal}>
          {isShiftsEditMode ? 'Edit Shift Info' : 'Add Shift Info'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSaveShift}>
            {Shiftsformvalues.map((shift, id) => (
              <div key={shift.id || id}> {/* Add unique key */}
                <FormGroup>
                  <Label for="emp_name">Employee</Label>
                  <Input
                    type="select"
                    name="emp_name"
                    id="emp_name"
                    value={Shiftsformvalues[0].emp_name}
                    onChange={(e) => handleShiftsFormChange(e, 0)}
                  >
                    <option value="">Select Employee</option>
                    {userOptions.map(user => (
                      <option key={user.value} value={user.label}>
                        {user.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="emp_no">Employee Number</Label>
                  <Input
                    type="text"
                    name="emp_no"
                    id="emp_no"
                    value={Shiftsformvalues[0].emp_no}
                    onChange={(e) => handleShiftsFormChange(e, 0)}
                    disabled
                  />
                </FormGroup>

                <FormGroup>
                  <Label for={`shift_${id}`}>Shift</Label>
                  <Input
                    type="select"
                    name="shift"
                    id={`shift_${id}`} // Add unique id
                    value={shift.shift || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  >
                    <option value="">Select Shift</option> {/* Default placeholder option */}
                    <option value="OPERATIONS SHIFT">OPERATIONS SHIFT</option>
                    <option value="10.30AM SHIFT">10.30AM SHIFT</option>
                    <option value="11AM SHIFT">11AM SHIFT</option>
                    <option value="9.30AM SHIFT">9.30AM SHIFT</option>
                    <option value="8AM SHIFT">8AM SHIFT</option>
                    <option value="9AM SHIFT">9AM SHIFT</option>
                    <option value="Dealing 1 SHIFT">DEALING 1 SHIFT</option>
                    <option value="Night SHIFT">NIGHT SHIFT</option>
                    <option value="Morning SHIFT">DEALING MORNING SHIFT</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for={`hours_${id}`}>Hours</Label>
                  <Input
                    type="text"
                    name="hours"
                    id={`hours_${id}`} // Add unique id
                    value={shift.hours || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`emp_code_${id}`}>Biometric ID</Label>
                  <Input
                    type="text"
                    name="emp_code"
                    id={`emp_code${id}`} // Add unique id
                    value={shift.emp_code || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for={`toDate_${id}`}>To Date</Label>
                  <Input
                    type="date"
                    name="toDate"
                    id={`toDate_${id}`} // Add unique id
                    value={shift.toDate || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  />
                </FormGroup> */}
              </div>
            ))}

            <ModalFooter>
              <Button color="primary" type="submit">
                {isShiftsEditMode ? 'Update' : 'Save'}
              </Button>
              <Button color="secondary" onClick={toggleShiftModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    );
  };

  // Render sheet modal
  const renderSheetModal = () => {
    if (!selectedSheet) return null;

    return (
      <Modal isOpen={sheetModal} toggle={() => toggleSheetModal()}>
        <ModalHeader toggle={() => toggleSheetModal()}>Sheet Details</ModalHeader>
        <ModalBody>
          <Label>Sheet Name: </Label>
          <p>{selectedSheet.sheetName}</p>

          <Label>From Date: </Label>
          <p>{selectedSheet.fromDate}</p>

          <Label>To Date: </Label>
          <p>{selectedSheet.toDate}</p>

          <Label>Successes: </Label>
          <p>{selectedSheet.successes}</p>

          <Label>Failures: </Label>
          <p>{selectedSheet.failures}</p>

          <Label>Status: </Label>
          <p>{selectedSheet.status}</p>

          <Label>Imported On: </Label>
          <p>{selectedSheet.importedOn}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleSheetModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleRowSelect = (id) => {
    const newSelectedRows = [...selectedRows];
    if (newSelectedRows.includes(id)) {
      newSelectedRows.splice(newSelectedRows.idOf(id), 1);
    } else {
      newSelectedRows.push(id);
    }
    setSelectedRows(newSelectedRows);
  };
  // Render Location modal
  const renderlocationModal = () => {

    return (
      <Modal isOpen={locationModal} toggle={togglelocationModal}>
        <ModalHeader toggle={togglelocationModal}>
          {islocationsEditMode ? 'Edit Location Info' : 'Add Location Info'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSaveLocation}>
            <FormGroup>
              <Label for="emp_name">Employee Name</Label>
              <Input
                type="text"
                name="emp_name"
                id="emp_name"
                value={locationsFormValues.emp_name || ''}
                onChange={handleLocationFormChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emp_no">Employee Number</Label>
              <Input
                type="text"
                name="emp_no"
                id="emp_no"
                value={locationsFormValues.emp_no || ''}
                onChange={handleLocationFormChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="geolocation">Geolocation</Label>
              <Input
                type="select"
                name="geolocation"
                id="geolocation"
                value={locationsFormValues.geolocation}
                onChange={handleLocationFormChange}
                required
              >
                <option value="">Select Location</option>
                <option value="Location 1">Location 1</option>
                <option value="Location 2">Location 2</option>
                <option value="Location 3">Location 3</option>
                <option value="Location 4">Location 4</option>
                <option value="Location 5">Location 5</option>
              </Input>
            </FormGroup>

            <ModalFooter>
              <Button color="primary" type="submit">
                {islocationsEditMode ? 'Update' : 'Save'}
              </Button>
              <Button color="secondary" onClick={togglelocationModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>

    );
  };
  // Render records modal
  const renderRecordsModal = () => {
    if (!selectedrecords) return null;

    return (
      <Modal isOpen={recordsModal} toggle={() => togglerecordsModal()}>
        <ModalHeader toggle={() => togglerecordsModal()}>Attendance Records</ModalHeader>
        <ModalBody>
          <Label>Employee Name: </Label>
          <p>{selectedrecords.emp_name}</p>

          <Label>Employee Number: </Label>
          <p>{selectedrecords.emp_no}</p>

          <Label>Attendance Card ID: </Label>
          <p>{selectedrecords.cardId}</p>

          <Label>Shifts: </Label>
          <p>{selectedrecords.shift}</p>

          <Label>Attendance Date: </Label>
          <p>{selectedrecords.date}</p>

          <Label>First In: </Label>
          <p>{selectedrecords.firstIn}</p>

          <Label>Last Out: </Label>
          <p>{selectedrecords.lastOut}</p>

          <Label>Extra Info: </Label>
          <p>{selectedrecords.extraInfo}</p>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => togglerecordsModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  // Render recommend modal
  const renderRecommendModal = () => {
    if (!selectedrecommend) return null;

    return (
      <Modal isOpen={recommendModal} toggle={() => togglerecommendModal()}>
        <ModalHeader toggle={() => togglerecommendModal()}>Recommendations</ModalHeader>
        <ModalBody>
          <Label>Employee Name: </Label>
          <p>{selectedrecommend.emp_name}</p>

          <Label>Employee Number: </Label>
          <p>{selectedrecommend.emp_no}</p>

          <Label>Attendance Date: </Label>
          <p>{selectedrecommend.date}</p>

          <Label>Entry Time: </Label>
          <p>{selectedrecommend.entrytime}</p>

          <Label>Exit Time: </Label>
          <p>{selectedrecommend.exittime}</p>

          <Label>Transaction Value: </Label>
          <p>{selectedrecommend.t_value}</p>

          <Label>Transaction Type: </Label>
          <p>{selectedrecommend.t_type}</p>



        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => togglerecommendModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };




  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: '1' })}
            onClick={() => toggleTab('1')}
            style={{
              cursor: 'pointer',
              color: activeTab === '1' ? '#194995' : 'black',
            }}
          >
            Shifts
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggleTab('2')}
            style={{
              cursor: 'pointer',
              color: activeTab === '2' ? '#194995' : 'black',
            }}
          >
            Attendance Sheets
          </NavLink>
        </NavItem> */}

        <NavItem>
          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggleTab('2')} style={{ cursor: 'pointer' }}>
            Attendance
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggleTab('3')}
            style={{
              cursor: 'pointer',
              color: activeTab === '3' ? '#194995' : 'black',
            }}
          >
            Employee GeoLocation
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => toggleTab('4')}
            style={{
              cursor: 'pointer',
              color: activeTab === '4' ? '#194995' : 'black',
            }}
          >
            Punch In Records
          </NavLink>
        </NavItem>
        {/*
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => toggleTab('5')}
            style={{
              cursor: 'pointer',
              color: activeTab === '5' ? '#194995' : 'black',
            }}
          >
            Recommendations
          </NavLink>
        </NavItem> */}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                Employee Shifts
              </h3>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input
                  type="text"
                  placeholder="Search Shifts"
                  value={shiftSearch}
                  onChange={handleSearchShiftChange}
                  style={{ width: '300px', marginBottom: '20px' }}
                />

                <PlusCircle size={18} onClick={() => initializeCreateShiftForm({})} style={{ cursor: 'pointer' }} />

              </div>
            </CardHeader>
            <style>
              {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
            </style>

            <CardBody >
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Employee Number</th>
                    <th>Shift</th>
                    <th>Hours</th>
                    {/* <th>From Date</th>
                    <th>To Date</th> */}
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {shifts
                    .filter(shift => shift.emp_name.toLowerCase().includes(shiftSearch.toLowerCase()))
                    .map(shift => (
                      <tr key={shift.id}>
                        <td>{shift.emp_name || "N/A"}</td> {/* Fix: Show emp_name */}
                        <td>{shift.emp_no}</td>
                        <td>{shift.shift}</td>
                        <td>{shift.hours}</td>
                        {/* <td>{shift.fromDate}</td>
                        <td>{shift.toDate}</td> */}
                        <td>
                          <Edit size={18} onClick={() => initializeUpdateShiftForm(shift)} style={{ cursor: 'pointer' }} />
                        </td>
                        <td>
                          <Trash size={18} onClick={() => deleteShift(shift.id)} style={{ cursor: 'pointer', color: 'red' }} />                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <Card>
            <CardHeader style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <h3 style={{ fontSize: "24px", fontWeight: "bold", color: "#194995", marginBottom: "10px" }}>
                Employee Attendance
              </h3>

              {/* Date Range & Search Input in One Line */}
              <div style={{ display: "flex", alignItems: "center", gap: "15px", flexWrap: "wrap" }}>
                {/* Date Range Filter */}
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <label style={{ fontWeight: "bold", color: "#333" }}>From:</label>
                  <Input type="date" value={startDate} onChange={handleStartDateChange} style={{ maxWidth: "160px" }} />

                  <label style={{ fontWeight: "bold", color: "#333" }}>To:</label>
                  <Input type="date" value={endDate} onChange={handleEndDateChange} style={{ maxWidth: "160px" }} />
                </div>

                {/* Search Input */}
                <Input
                  type="text"
                  placeholder="🔍 Search by Name or Biometric ID"
                  value={attendanceSearch}
                  onChange={(e) => setAttendanceSearch(e.target.value)}
                  style={{
                    alignItems: "right",
                    maxWidth: "300px",
                    padding: "8px 12px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    outline: "none",
                    transition: "0.3s ease-in-out",
                  }}
                />
              </div>
            </CardHeader>

            <CardBody>
              {loading ? (
                <div style={{ textAlign: 'center' }}>
                  <Spinner color="primary" /> Loading Attendance Data...
                </div>
              ) : (
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Employee Name</th>
                      <th>Biometric ID</th>
                      <th>Date</th>
                      <th>Status</th>
                      {/* <th>Times</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {employeeAttendanceStatus
                      .filter(record =>
                        record.emp_name.toLowerCase().includes(attendanceSearch.toLowerCase()) ||
                        record.emp_no.includes(attendanceSearch)
                      )
                      .map((record, index) => (
                        <tr key={index}>
                          <td>{record.emp_name}</td>
                          <td>{record.emp_no}</td>
                          <td>{record.date}</td>
                          <td style={{ color: record.status === 'Present' ? 'green' : 'red' }}>{record.status}</td>
                          {/* <td>{record.times}</td> */}
                        </tr>
                      ))}
                  </tbody>
                </Table>

              )}
            </CardBody>
          </Card>
        </TabPane>
        {/* <TabPane tabId="2">
          <Card>
            <CardHeader>
              <h3>Attendance Sheets</h3>
              <Input
                type="text"
                placeholder="Search Attendence Sheets"
                value={sheetSearch}
                onChange={(e) => setSheetSearch(e.target.value)}
              />

            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Sheet Name</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Successes</th>
                    <th>Failures</th>
                    <th>Status</th>
                    <th>Imported On</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSheets.map((sheet) => (
                    <tr key={sheet.id}>
                      <td>
                        <Input
                          type="checkbox"
                          checked={selectedRows.includes(sheet.id)}
                          onChange={() => handleRowSelect(sheet.id)}
                        />

                      </td>
                      <td>{sheet.sheetName}</td>
                      <td>{sheet.fromDate}</td>
                      <td>{sheet.toDate}</td>
                      <td>{sheet.successes}</td>
                      <td>{sheet.failures}</td>
                      <td>{sheet.status}</td>
                      <td>{sheet.importedOn}</td>
                      <td>
                        
                        <Edit size={18} onClick={() => toggleSheetModal(sheet)} style={{ cursor: 'pointer' }} />

                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane> */}
        <TabPane tabId="3">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                Employee Geolocation
              </h3>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input
                  type="text"
                  placeholder="Search  Employee Geolocation"
                  value={locationSearch}
                  onChange={handleSearchlocationChange}
                  style={{ width: '300px', marginBottom: '20px' }}
                />

                <PlusCircle size={18} onClick={() => initializeCreateLocationForm({})} style={{ cursor: 'pointer' }} />

              </div>
            </CardHeader>
            <CardBody className="scrollable-table-container">
              <Table bordered hover className="scrollable-table">
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Employee Number</th>
                    <th>Geolocation</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredlocations.map((location) => (
                    <tr key={location.id}>
                      <td>{location.emp_name}</td>
                      <td>{location.emp_no}</td>
                      <td>{location.geolocation}</td>
                      <td>

                        <Edit size={18} onClick={() => initializeUpdateLocationForm(location)} style={{ cursor: 'pointer' }} />

                      </td>
                      <td>

                        <Trash size={18} onClick={() => deleteLocation(location.id)} style={{ cursor: 'pointer', color: 'red' }} />

                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <p>Showing {locations.length} results</p>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="4">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: "24px", fontWeight: "bold", color: "#194995", marginBottom: "20px" }}>
                Punch In Records
              </h3>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {/* Date Range Pickers */}
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <label style={{ fontWeight: "bold" }}>From:</label>
                  <Input type="date" value={startDate} onChange={handleStartDateChange} />
                  <label style={{ fontWeight: "bold" }}>To:</label>
                  <Input type="date" value={endDate} onChange={handleEndDateChange} />
                </div>

                {/* Search Input */}
                <Input
                  type="text"
                  placeholder="Search Employee Name or Biometric ID"
                  value={attendanceSearch}
                  onChange={(e) => setAttendanceSearch(e.target.value)}
                  style={{ width: "300px", marginBottom: "20px" }}
                />
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <div style={{ textAlign: 'center' }}>
                  <Spinner color="primary" /> Loading Attendance Records...
                </div>
              ) : (
                <Table bordered hover>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Biometric ID</th>
                    <th>Date</th>
                    <th>First In</th>
                    <th>Last Out</th>
                  </tr>
                </thead>
                <tbody>
                  {dailyPunchRecords
                    .filter(record =>
                      record.emp_name.toLowerCase().includes(attendanceSearch.toLowerCase()) ||
                      record.emp_no.includes(attendanceSearch)
                    )
                    .map((record, index) => (
                      <tr key={index}>
                        <td>{record.emp_name}</td>
                        <td>{record.emp_no}</td>
                        <td>{record.date}</td>
                        <td>{record.firstIn}</td>
                        <td>{record.lastOut}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              
              )}
              <p>Showing {filteredAttendance.length} results</p>
            </CardBody>
          </Card>
        </TabPane>



        <TabPane tabId="5">
          <Card>
            <CardHeader>
              <h3>Recommendations</h3>
              <Input
                type="text"
                placeholder="Search Recommendations"
                value={recommendSearch}
                onChange={(e) => setrecommendSearch(e.target.value)}
              />

            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Emp. Name</th>
                    <th>Emp. No.</th>
                    <th>Attendance Date</th>
                    <th>Entry Time</th>
                    <th>Exit Time</th>
                    <th>Transaction Value</th>
                    <th>Transaction Type</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecommendations.map((recommend) => (
                    <tr key={recommend.id}>
                      <td>{recommend.emp_name}</td>
                      <td>{recommend.emp_no}</td>
                      <td>{recommend.date}</td>
                      <td>{recommend.entrytime}</td>
                      <td>{recommend.exittime}</td>
                      <td>{recommend.t_value}</td>
                      <td>{recommend.t_type}</td>
                      <td>

                        <Edit size={18} onClick={() => togglerecommendModal(recommend)} style={{ cursor: 'pointer' }} />

                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>

      {renderShiftModal()}
      {renderSheetModal()}
      {renderlocationModal()}
      {renderRecordsModal()}
      {renderRecommendModal()}
    </>
  );
};

export default Attendance;