import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { getUserOptions } from 'Services/user.service';

const ProfessionalInfoModal = ({ isOpen, toggle, formValues, handleFormChange, handleSaveUserInfo }) => {
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    const fetchUserOptions = async () => {
      try {
        const users = await getUserOptions();
        if (Array.isArray(users)) {
          setUserOptions(users);
        } else {
          console.error('Expected an array but got:', users);
          setUserOptions([]);
        }
      } catch (error) {
        console.error('Error fetching user options:', error);
        setUserOptions([]);
      }
    };
    fetchUserOptions();
  }, []);

  const handleManagerChange = (event) => {
    const selectedManager = userOptions.find(user => user.id === event.target.value);
    handleFormChange({
      target: { name: 'direct_manager', value: selectedManager ? selectedManager.name : '' }
    });
    handleFormChange({
      target: { name: 'direct_managerId', value: selectedManager ? selectedManager.id : '' }
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Professional Info</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="job_title">Job Title</Label>
            <Input
              type="text"
              name="job_title"
              id="job_title"
              value={formValues.job_title}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="branch">Branch</Label>
            <Input
              type="text"
              name="branch"
              id="branch"
              value={formValues.branch}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="work_type">Work Type</Label>
            <Input
              type="text"
              name="work_type"
              id="work_type"
              value={formValues.work_type}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="work_location">Work Location</Label>
            <Input
              type="text"
              name="work_location"
              id="work_location"
              value={formValues.work_location}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="direct_manager">Direct Manager</Label>
            <Input
              type="select"
              name="direct_manager"
              id="direct_manager"
              value={formValues.direct_managerId || ''}
              onChange={handleManagerChange}
            >
              <option value="">Select a manager</option>
              {Array.isArray(userOptions) && userOptions.map(user => (
                <option key={user.id} value={user.id}>{user.name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="department">Department</Label>
            <Input
              type="text"
              name="department"
              id="department"
              value={formValues.department}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="hiring_date">Hiring Date</Label>
            <Input
              type="date"
              name="hiring_date"
              id="hiring_date"
              value={formValues.hiring_date}
              onChange={handleFormChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="end_of_probation">End of Probation</Label>
            <Input
              type="date"
              name="end_of_probation"
              id="end_of_probation"
              value={formValues.end_of_probation}
              onChange={handleFormChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveUserInfo}>
          Update
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfessionalInfoModal;