
import React, { useState, useEffect } from 'react';
import { Search, X } from 'react-feather';

const Searchbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSearchOverlay = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle('search-open', !isOpen);
  };

  // Close search overlay when pressing the "Escape" key
  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        toggleSearchOverlay();
      }
    };
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [isOpen]);

  return (
    <li>
<span className="header-search" style={{ display: "none" }}>
  <Search onClick={toggleSearchOverlay} />
</span>


      {isOpen && (
        <div className="search-full open">
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Search..."
              autoFocus
            />
            <button className="close-btn" onClick={toggleSearchOverlay}>
              <X />
            </button>
          </div>
        </div>
      )}
    </li>
  );
};

export default Searchbar;