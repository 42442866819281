import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    Table,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
} from 'reactstrap';
import { Edit, Trash, PlusCircle } from "react-feather";
import { fetchUsersCommissions, createUsersCommissions, updateUsersCommissions, deleteUsersCommissions } from 'Services/userCommissions.service';
import { getUserOptions } from 'Services/user.service';

const EmployeeCommissions = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [commissions, setCommissions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [formValues, setFormValues] = useState({
        empName: '',
        empNo: '',
        Commission: '',
        monthYear: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        loadData();
        loadUserOptions();
    }, []);

    const loadData = async () => {
        try {
            const data = await fetchUsersCommissions();
            if (data && Array.isArray(data.rows)) {
                setCommissions(data.rows);
            } else {
                console.error('Unexpected response format:', data);
            }
        } catch (error) {
            console.error('Error fetching commissions:', error);
        }
    };

    const loadUserOptions = async () => {
        try {
            const response = await getUserOptions();
            if (response?.data?.data) {
                const formattedOptions = response.data.data.map(user => ({
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`
                }));
                setUserOptions(formattedOptions);
            }
        } catch (error) {
            console.error('Failed to fetch user options:', error);
        }
    };

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    
    const handleFormChange = (e, id) => {
        const { name, value } = e.target;
        const updatedData = [...formValues];

        if (name === 'empName') {
            const selectedEmployee = userOptions.find(emp => `${emp.label}` === value);

            updatedData[id] = {
                ...updatedData[id],
                empName: value,
                empNo: selectedEmployee ? selectedEmployee.value : '' // Auto-fill employee number
            };
        } else {
            updatedData[id] = { ...updatedData[id], [name]: value };

            // If the total salary changes, calculate the basic, accommodation, and transportation value
        }

        setFormValues(updatedData);
    };

    const initializeCreateForm = () => {
        setIsEditMode(false);
        setFormValues({ empName: '', empNo: '', Commission: '', monthYear: '' });
        toggleModal();
    };

    const initializeUpdateForm = (commission) => {
        setIsEditMode(true);
        setFormValues(commission);
        toggleModal();
    };

    const handleDelete = async (id) => {
        try {
            await deleteUsersCommissions(id);
            toast.success('Commission deleted successfully!');
            setCommissions(prev => prev.filter(item => item.id !== id));
        } catch (error) {
            console.error('Error deleting commission:', error);
            toast.error('Failed to delete commission. Please try again.');
        }
    };

    const handleSaveData = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                await updateUsersCommissions(formValues.id, formValues);
                toast.success('Commission updated successfully!');
            } else {
                await createUsersCommissions(formValues);
                toast.success('Commission added successfully!');
            }
            await loadData();
            toggleModal();
        } catch (error) {
            console.error('Error saving commission:', error);
            toast.error('Failed to save commission. Please try again.');
        }
    };

    const filteredCommissions = commissions.filter(({ empName, empNo }) =>
        empName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        empNo.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Card>
            <CardHeader>
                <h3>Employee Commissions</h3>
                <div className="d-flex justify-content-between">
                    <Input
                        type="text"
                        placeholder="Search by Employee Name or Number"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <PlusCircle size={18} onClick={initializeCreateForm} style={{ cursor: 'pointer' }} />
                </div>
            </CardHeader>
            <CardBody>
                <Table hover bordered>
                    <thead>
                        <tr>
                            <th>Employee Name</th>
                            <th>Employee Number</th>
                            <th>Commission (AED)</th>
                            <th>Month/Year</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCommissions.map((commission) => (
                            <tr key={commission.id}>
                                <td>{commission.empName}</td>
                                <td>{commission.empNo}</td>
                                <td>{commission.Commission}</td>
                                <td>{commission.monthYear}</td>
                                <td>
                                    <Edit size={18} onClick={() => initializeUpdateForm(commission)} style={{ cursor: 'pointer' }} />
                                    <Trash size={18} onClick={() => handleDelete(commission.id)} style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{isEditMode ? 'Edit Commission' : 'Add Commission'}</ModalHeader>
                <ModalBody>
                    <Form> <FormGroup>
                        <Label for="empName">Employee Name</Label>
                        <Input
                            type="select"
                            name="empName"
                            id="empName"
                            value={formValues.empName}
                            onChange={handleFormChange}
                        >
                            <option value="">Select Employee</option>
                            {userOptions.map(user => (
                                <option key={user.value} value={user.label}>
                                    {user.label}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                        <FormGroup>
                            <Label for="empNo">Employee Number</Label>
                            <Input
                                type="text"
                                name="empNo"
                                id="empNo"
                                value={formValues.empNo}
                                onChange={handleFormChange}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Commission">Commission Amount</Label>
                            <Input type="number" name="Commission" value={formValues.Commission} onChange={handleFormChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="monthYear">Month/Year</Label>
                            <Input type="text" name="monthYear" value={formValues.monthYear} onChange={handleFormChange} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveData}>{isEditMode ? 'Update' : 'Add'}</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Card>
    );
};

export default EmployeeCommissions;
