import React, { Fragment, useState } from "react";
import { Btn } from "../../../AbstractElements";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const DeleteModalButton = ({ btnText, field, rowId, onConfirmDelete }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <Fragment>
      <Btn attrBtn={{ color: "danger", onClick: toggle }}>{btnText}</Btn>
      <DeleteConfirmationModal
        modal={modal}
        toggle={toggle}
        field={field}
        rowId={rowId}
        onConfirmDelete={onConfirmDelete} // Pass function reference
      />
    </Fragment>
  );
};

export default DeleteModalButton;
