import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table } from "reactstrap";
import { Deposits } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { Row } from "react-bootstrap";
import DepositModalButton from "./DepositModalButton";
import DeleteModalButton from './DeleteModalButton';

import ApprovedModal from "./Approved/ApprovedFormModal";
import RejectModal from "./Reject/RejectFormModal";
import { transactionsService } from "Services";
import Select from 'react-select';
import TableRowStatus from "CommonElements/Table/TableRowStatus";
import TableRowApprove from "CommonElements/Table/TableRowApprove";
import TableRowReject from "CommonElements/Table/TableRowReject";
import TablePagination from "CommonElements/Table/TablePagination";
import { formatDate, appendParameterToUrl, getParamsAndBody } from '../../Common/Utility';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import { toast } from 'react-toastify';
import { DocumentsTransaction } from '../../../Services';
import GatewayModal from "./GatewayModal"; // Import the new modal



const HoverableRowsClass = () => {

  const profiledata = useSelector((state) => state.login?.profile);
  const roleId = profiledata?.roleId || null;

  const [approvedModal, setApprovedModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [approvedModalData, setApprovedModalData] = useState([]);
  const [rejectModalData, setRejectModalData] = useState([]);
  const [limit, setLimit] = useState(10);
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const [gatewayModal, setGatewayModal] = useState(false);
  const [rec, setRec] = useState({});
  const [expandedRow, setExpandedRow] = useState(null); // Track expanded row ID
  const [currentField, setCurrentField] = useState(null); // State to track the field to delete
  const [currentRowId, setCurrentRowId] = useState(null); // State to track the ID of the row


  useEffect(() => {
    getListRecord(1);
  }, []);

  useEffect(() => {
    console.log("currentField:", currentField);
    console.log("currentRowId:", currentRowId);
  }, [currentField, currentRowId]);


  const getListRecord = (page = 1) => {
    const currentUrl = window.location.href;
    const query = getParamsAndBody(currentUrl);
    page = query.page || page;
    setRec({
      loading: true,
      data: [],
    });
    transactionsService
      .getCountryTransactions({ type: "DEPOSIT", page, limit: limit })
      .then((res) => {
        // console.log("details for deposit is.......... ", res);
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };

  const toggle = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      console.log("Data is ", rowData);
      setApprovedModalData({ ...rowData });
    }
    setApprovedModal(!approvedModal);
  };

  //Reject Transaction
  const rejecttl = (e, rowData) => {
    e.preventDefault();
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      setRejectModalData({ ...rowData });
    }
    setRejectModal(!rejectModal);
  };

  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState(rec);

  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    setFilter(inputValue);
  };

  // Watch for changes in rec and filter, and update filteredData
  useEffect(() => {
    // Filter rec based on the input value
    const newFilteredData = rec && rec.data && rec.data.filter((item) => {
      return (item && item.id && item.id.toString().includes(filter)) || (item && item["Account.login"] && item["Account.login"].toString().includes(filter)) ||
        (item && item.gateway &&
          item.gateway.toLowerCase().includes(filter.toLowerCase())) ||
        (item && item.amount &&
          item.amount.toString().includes(filter.toLowerCase())) ||
        (item && item.status &&
          item.status.toLowerCase().includes(filter.toLowerCase()));
    });

    // Update the filtered data
    setFilteredData(newFilteredData);
  }, [rec, filter]);

  const options = [
    { value: '10', label: '10' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '500', label: '500' },
    { value: '1000', label: '1000' }
  ]
  // Function to handle limit change
  const handleLimitChange = (selectedOption) => {
    const newLimit = selectedOption.value; // Use the selected option's value directly
    setLimit(newLimit); // Update the limit state
    transactionsService
      .getCountryTransactions({ type: "DEPOSIT", page: 1, limit: newLimit })
      .then((res) => {
        console.log("details for deposit is .................................................", res);
        setRec({
          ...res,
          limit: newLimit,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };

  const toggleExpandRow = (rowId) => {
    setExpandedRow((prev) => (prev === rowId ? null : rowId));
  };

  const [editRowId, setEditRowId] = useState(null); // Track editing row ID
  const [formData, setFormData] = useState({}); // Store editable form data

  const handleEdit = (rowData) => {
    setEditRowId(rowData.id);
    setFormData({ ...rowData });
  };

  const handleSave = () => {
    console.log("Saved Data:", formData);

    // Convert all fields to strings, defaulting to an empty string if not filled
    const payload = {
      receivedBank: String(formData.receivedBank || ""), // Ensure string or empty
      bankCurrency: String(formData.bankCurrency || ""), // Ensure string or empty
      netMarkupsReceived: String(formData.netMarkupsReceived || ""), // Ensure string or empty
      bankReferenceNo: String(formData.bankReferenceNo || ""), // Ensure string or empty
      internalNotes: String(formData.internalNotes || ""), // Ensure string or empty
      depositedPerson: String(formData.createdBy || ""), // Ensure string or empty
      ibno: String(formData.IBid || ""),
      ibname: String(formData.ibname || ""),
      entity: String(formData.entity || ""),
      salesperson: String(formData.salesperson || ""),
      reconcile: formData.reconcile ? 1 : 0,
      reconcileReviewedBy: String(formData.reconcileReviewedBy || ""), // Ensure string or empty
      locked: formData.locked ? 1 : 0,
      doc1: String(formData.doc1 || ""),
      doc2: String(formData.doc2 || ""),
      doc3: String(formData.doc3 || ""),
      doc4: String(formData.doc4 || ""),
    };

    console.log("Payload being sent:", payload);

    transactionsService
      .patchDeposit(formData.id, payload)
      .then((res) => {
        console.log("Update Successful:", res);
        // Update only the modified record in the state
        setRec((prevRec) => ({
          ...prevRec,
          data: prevRec.data.map((item) =>
            item.id === formData.id ? { ...item, ...payload } : item
          ),
        }));
        setEditRowId(null);
        setFormData({});
        toast.success("Record saved successfully!");
      })
      .catch((err) => {
        console.error("Error updating deposit:", err);

        // Extract error details from the response
        const errorMessage =
          err.response?.data?.message || err.message || "Unknown error occurred";

        // Show detailed error message
        toast.error(`Failed to update deposit: ${errorMessage}`);
      });
  };

  const getDocumentName = (key) => {
    const mappings = {
      doc1: "document1",
      doc2: "document2",
      doc3: "document3",
      doc4: "document4",
    };
    return mappings[key] || key;
  };




  const handleFileUpload = (e, field) => {
    const file = e.target.files[0]; // Get the selected file
    console.log("file data: ", file);

    if (!file) {
      toast.warn("No file selected for upload.");
      return;
    }

    const formData = new FormData();
    formData.append("transactionId", expandedRow); // Associate transactionId
    formData.append(getDocumentName(field), file); // Use dynamic document name

    console.log("Uploading file with data: ", formData);

    DocumentsTransaction.addRecordTransaction(formData)
      .then((response) => {
        // Validate if response is an array and extract the first object's `id`
        if (Array.isArray(response) && response.length > 0 && response[0]?.id) {
          const uploadedFileId = response[0].id; // Extract the uploaded file's ID
          console.log("File uploaded successfully:", response[0]);

          // Update the formData with the uploaded file's `id`
          setFormData((prevData) => ({
            ...prevData,
            [field]: uploadedFileId, // Dynamically update the corresponding field
          }));

          // Save the `id` using transactionsService API
          const payload = { [field]: uploadedFileId }; // Construct payload
          transactionsService
            .patchDeposit(expandedRow, payload) // Update the transaction with the new `doc1`, `doc2`, etc.
            .then(() => {
              toast.success("File uploaded and associated successfully!");
              // getListRecord(); // Refresh records if necessary  if need to refresh then use it 
            })
            .catch((error) => {
              console.error("Error associating file with transaction:", error);
              const errorMessage =
                error.response?.data?.message || error.message || "Unknown error occurred";
              toast.error(`File upload succeeded but association failed: ${errorMessage}`);
            });
        } else {
          console.error("Unexpected response format:", response);
          toast.error("File upload failed: Unexpected response format.");
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        const errorMessage =
          error.response?.data?.message || error.message || "Unknown error occurred";
        toast.error(`Failed to upload file: ${errorMessage}`);
      });
  };

  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);


  const handleViewDocument = (documentUrl) => {
    setCurrentDocument(documentUrl);
    setViewModal(true);
  };

  const handleDeleteDocument = (field, rowId) => {
    console.log(`Delete button clicked for field: ${field}, rowId: ${rowId}`);
    setCurrentField(field);
    setCurrentRowId(rowId);
    setDeleteModal(true);

    console.log("Updated state values:", {
      currentField: field,
      currentRowId: rowId,
      deleteModal: true,
    });
  };

  const confirmDeleteDocument = (field, rowId) => {
    console.log("Confirm delete triggered", { field, rowId });

    if (field && rowId) {
      const payload = { [field]: true };

      console.log("Payload for API call:", payload);
      transactionsService
        .deleteTransactionDocs(rowId, payload)
        .then((response) => {
          console.log("API Response:", response);
          toast.success(`Document ${field} deleted successfully.`);
          setDeleteModal(false);
          getListRecord(); // Refresh the data
        })
        .catch((err) => {
          console.error("API Error:", err);
          const errorMessage =
            err.response?.data?.message || err.message || "Unknown error occurred";
          toast.error(`Failed to delete document: ${errorMessage}`);
        });
    } else {
      console.warn("No field or row ID set for deletion.");
    }
  };



  const handleCancel = () => {
    setEditRowId(null);
    setFormData({});
  };

  const handleChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const profile = useSelector((state) => state.login && state.login.profile);

  const userName =
    (profile && profile.name) ||
    `${profile?.firstName || ""} ${profile?.lastName || ""}`.trim() ||
    "Unknown User";

  const renderSubTable = (rowData) => {
    const isEditing = editRowId === rowData.id;

    const allowedProfileIds = [65, 66, 41]; // IDs that are allowed
    const isDisabled = !allowedProfileIds.includes(profile?.id); // Check if the field should be disabled


    // Inline Styles
    const rowStyle = { backgroundColor: "#f0f8ff" }; // Light blue for rows
    const cellStyle = { backgroundColor: "#e6e6fa", padding: "10px" }; // Lavender for cells
    const contentStyle = { backgroundColor: "#e6e6fa", padding: "5px", display: "block" }; // Same for strong & div
    // Check if the user is an admin


    const isAdmin = profile?.Role?.title === 'Admin1';
    //  console.log("edit permission .......................................",isAdmin)

    return (
      <tr>
        <td colSpan="14" style={{ padding: "0px" }}>
          <Table bordered className="mt-2">
            {<thead>
              <tr style={rowStyle}>
                <th colSpan="8" className="text-center">Bank Transaction Details</th>
              </tr>
            </thead>}
            <tbody>
              {/* First Row */}
              <tr style={rowStyle}>
                <td style={cellStyle}>
                  <strong style={contentStyle}>Amount Received:</strong>
                  {isEditing ? (
                    <input
                      type="text" // Use text for custom validation
                      value={formData.receivedBank || ""}
                      onChange={(e) => handleChange(e, "receivedBank")}
                      onInput={(e) => {
                        // Allow numbers and a single decimal point only
                        e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Remove invalid characters
                        e.target.value = e.target.value.replace(/(\..*)\./g, "$1"); // Prevent multiple decimal points
                      }}
                      className="form-control mt-1"
                      placeholder="Enter amount"
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.receivedBank || "N/A"}</div>
                  )}
                </td>

                <td style={cellStyle}>
                  <strong style={contentStyle}>Bank Currency:</strong>
                  {isEditing ? (
                    <select
                      value={formData.bankCurrency || ""}
                      onChange={(e) => handleChange(e, "bankCurrency")}
                      className="form-control mt-1"
                    >
                      <option value="">Select Currency</option>
                      <option value="AED">AED</option>
                      <option value="AFN">AFN</option>
                      <option value="ALL">ALL</option>
                      <option value="AMD">AMD</option>
                      <option value="ANG">ANG</option>
                      <option value="AOA">AOA</option>
                      <option value="ARS">ARS</option>
                      <option value="AUD">AUD</option>
                      <option value="AWG">AWG</option>
                      <option value="AZN">AZN</option>
                      <option value="BAM">BAM</option>
                      <option value="BBD">BBD</option>
                      <option value="BDT">BDT</option>
                      <option value="BGN">BGN</option>
                      <option value="BHD">BHD</option>
                      <option value="BIF">BIF</option>
                      <option value="BMD">BMD</option>
                      <option value="BND">BND</option>
                      <option value="BOB">BOB</option>
                      <option value="BRL">BRL</option>
                      <option value="BSD">BSD</option>
                      <option value="BTN">BTN</option>
                      <option value="BWP">BWP</option>
                      <option value="BYN">BYN</option>
                      <option value="BZD">BZD</option>
                      <option value="CAD">CAD</option>
                      <option value="CDF">CDF</option>
                      <option value="CHF">CHF</option>
                      <option value="CLP">CLP</option>
                      <option value="CNY">CNY</option>
                      <option value="COP">COP</option>
                      <option value="CRC">CRC</option>
                      <option value="CUP">CUP</option>
                      <option value="CVE">CVE</option>
                      <option value="CZK">CZK</option>
                      <option value="DJF">DJF</option>
                      <option value="DKK">DKK</option>
                      <option value="DOP">DOP</option>
                      <option value="DZD">DZD</option>
                      <option value="EGP">EGP</option>
                      <option value="ERN">ERN</option>
                      <option value="ETB">ETB</option>
                      <option value="EUR">EUR</option>
                      <option value="FJD">FJD</option>
                      <option value="FKP">FKP</option>
                      <option value="FOK">FOK</option>
                      <option value="GBP">GBP</option>
                      <option value="GEL">GEL</option>
                      <option value="GGP">GGP</option>
                      <option value="GHS">GHS</option>
                      <option value="GIP">GIP</option>
                      <option value="GMD">GMD</option>
                      <option value="GNF">GNF</option>
                      <option value="GTQ">GTQ</option>
                      <option value="GYD">GYD</option>
                      <option value="HKD">HKD</option>
                      <option value="HNL">HNL</option>
                      <option value="HRK">HRK</option>
                      <option value="HTG">HTG</option>
                      <option value="HUF">HUF</option>
                      <option value="IDR">IDR</option>
                      <option value="ILS">ILS</option>
                      <option value="IMP">IMP</option>
                      <option value="INR">INR</option>
                      <option value="IQD">IQD</option>
                      <option value="IRR">IRR</option>
                      <option value="ISK">ISK</option>
                      <option value="JEP">JEP</option>
                      <option value="JMD">JMD</option>
                      <option value="JOD">JOD</option>
                      <option value="JPY">JPY</option>
                      <option value="KES">KES</option>
                      <option value="KGS">KGS</option>
                      <option value="KHR">KHR</option>
                      <option value="KID">KID</option>
                      <option value="KMF">KMF</option>
                      <option value="KRW">KRW</option>
                      <option value="KWD">KWD</option>
                      <option value="KYD">KYD</option>
                      <option value="KZT">KZT</option>
                      <option value="LAK">LAK</option>
                      <option value="LBP">LBP</option>
                      <option value="LKR">LKR</option>
                      <option value="LRD">LRD</option>
                      <option value="LSL">LSL</option>
                      <option value="LYD">LYD</option>
                      <option value="MAD">MAD</option>
                      <option value="MDL">MDL</option>
                      <option value="MGA">MGA</option>
                      <option value="MKD">MKD</option>
                      <option value="MMK">MMK</option>
                      <option value="MNT">MNT</option>
                      <option value="MOP">MOP</option>
                      <option value="MRU">MRU</option>
                      <option value="MUR">MUR</option>
                      <option value="MVR">MVR</option>
                      <option value="MWK">MWK</option>
                      <option value="MXN">MXN</option>
                      <option value="MYR">MYR</option>
                      <option value="MZN">MZN</option>
                      <option value="NAD">NAD</option>
                      <option value="NGN">NGN</option>
                      <option value="NIO">NIO</option>
                      <option value="NOK">NOK</option>
                      <option value="NPR">NPR</option>
                      <option value="NZD">NZD</option>
                      <option value="OMR">OMR</option>
                      <option value="PAB">PAB</option>
                      <option value="PEN">PEN</option>
                      <option value="PGK">PGK</option>
                      <option value="PHP">PHP</option>
                      <option value="PKR">PKR</option>
                      <option value="PLN">PLN</option>
                      <option value="PYG">PYG</option>
                      <option value="QAR">QAR</option>
                      <option value="RON">RON</option>
                      <option value="RSD">RSD</option>
                      <option value="RUB">RUB</option>
                      <option value="RWF">RWF</option>
                      <option value="SAR">SAR</option>
                      <option value="SBD">SBD</option>
                      <option value="SCR">SCR</option>
                      <option value="SDG">SDG</option>
                      <option value="SEK">SEK</option>
                      <option value="SGD">SGD</option>
                      <option value="SHP">SHP</option>
                      <option value="SLE">SLE</option>
                      <option value="SOS">SOS</option>
                      <option value="SRD">SRD</option>
                      <option value="SSP">SSP</option>
                      <option value="STN">STN</option>
                      <option value="SYP">SYP</option>
                      <option value="SZL">SZL</option>
                      <option value="THB">THB</option>
                      <option value="TJS">TJS</option>
                      <option value="TMT">TMT</option>
                      <option value="TND">TND</option>
                      <option value="TOP">TOP</option>
                      <option value="TRY">TRY</option>
                      <option value="TTD">TTD</option>
                      <option value="TVD">TVD</option>
                      <option value="TWD">TWD</option>
                      <option value="TZS">TZS</option>
                      <option value="UAH">UAH</option>
                      <option value="UGX">UGX</option>
                      <option value="USD">USD</option>
                      <option value="UYU">UYU</option>
                      <option value="UZS">UZS</option>
                      <option value="VES">VES</option>
                      <option value="VND">VND</option>
                      <option value="VUV">VUV</option>
                      <option value="WST">WST</option>
                      <option value="XAF">XAF</option>
                      <option value="XCD">XCD</option>
                      <option value="XDR">XDR</option>
                      <option value="XOF">XOF</option>
                      <option value="XPF">XPF</option>
                      <option value="YER">YER</option>
                      <option value="ZAR">ZAR</option>
                      <option value="ZMW">ZMW</option>
                      <option value="ZWL">ZWL</option>
                    </select>
                  ) : (
                    <div style={contentStyle}>{rowData.bankCurrency || "N/A"}</div>
                  )}
                </td>
                <td style={cellStyle}>
                  <strong style={contentStyle}>Net Markups Received:</strong>
                  {isEditing ? (
                    <input
                      type="text" // Use 'text' for custom validation
                      value={formData.netMarkupsReceived || ""}
                      onChange={(e) => handleChange(e, "netMarkupsReceived")}
                      onInput={(e) => {
                        // Allow only numbers and a single decimal point
                        e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Remove invalid characters
                        e.target.value = e.target.value.replace(/(\..*)\./g, "$1"); // Prevent multiple decimal points
                      }}
                      className="form-control mt-1"
                      placeholder="Enter amount"
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.netMarkupsReceived || "N/A"}</div>
                  )}
                </td>

                <td style={cellStyle}>
                  <strong style={contentStyle}>Bank Reference No:</strong>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.bankReferenceNo || ""}
                      onChange={(e) => handleChange(e, "bankReferenceNo")}
                      className="form-control mt-1"
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.bankReferenceNo || "N/A"}</div>
                  )}
                </td>
                <td style={cellStyle}>
                  <strong style={contentStyle}>Internal Notes:</strong>
                  {isEditing ? (
                    <textarea
                      value={formData.internalNotes || ""}
                      onChange={(e) => handleChange(e, "internalNotes")}
                      className="form-control mt-1"
                      rows="1" // Adjust rows based on your UI preference
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.internalNotes || "N/A"}</div>
                  )}
                </td>

                <td style={cellStyle}>
                  <strong style={contentStyle}>D/W Person:</strong>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.createdBy || ""}
                      onChange={(e) => handleChange(e, "depositedPerson")}
                      className="form-control mt-1"
                      disabled={true} // Always disabled
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.createdBy || "N/A"}</div>
                  )}
                </td>


                <td style={cellStyle}>
                  <strong style={contentStyle}>IB No:</strong>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.IBid || ""}
                      onChange={(e) => handleChange(e, "IBid")}
                      className="form-control mt-1"
                      disabled={true}
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.IBid || "N/A"}</div>
                  )}
                </td>
                <td style={cellStyle}>
                  <strong style={contentStyle}>IB Name:</strong>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.ibname || ""}
                      onChange={(e) => handleChange(e, "ibName")}
                      className="form-control mt-1"
                      disabled={true}
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.ibname || "N/A"}</div>
                  )}
                </td>
              </tr>

              {/* Second Row */}
              <tr style={rowStyle}>

                <td style={cellStyle}>
                  <strong style={contentStyle}>Entity:</strong>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.entity || ""}
                      onChange={(e) => handleChange(e, "entity")}
                      className="form-control mt-1"
                      disabled={true}
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.entity || "N/A"}</div>
                  )}
                </td>
                <td style={cellStyle}>
                  <strong style={contentStyle}>RM Name:</strong>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.salesperson || ""}
                      onChange={(e) => handleChange(e, "salesperson")}
                      className="form-control mt-1"
                      disabled={true}
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.salesperson || "N/A"}</div>
                  )}
                </td>

                <td style={cellStyle}>
                  <strong style={contentStyle}>Documents:</strong>
                  {!isEditing ? (
                    <div style={contentStyle}>
                      {rowData.doc1 ? (
                        <div style={contentStyle}>
                          <button
                            className="btn btn-link"
                            onClick={() => handleViewDocument(rowData.doc1)}
                          >
                            View
                          </button>
                          {!rowData.locked && (
                            <DeleteModalButton
                              btnText="Delete"
                              field="doc1"
                              rowId={rowData.id}
                              onConfirmDelete={confirmDeleteDocument}
                            />
                          )}
                        </div>
                      ) : (
                        <div style={contentStyle}>N/A</div>
                      )}
                      {rowData.doc2 ? (
                        <div style={contentStyle}>
                          <button
                            className="btn btn-link"
                            onClick={() => handleViewDocument(rowData.doc2)}
                          >
                            View
                          </button>
                          {!rowData.locked && (
                            <DeleteModalButton
                              btnText="Delete"
                              field="doc2"
                              rowId={rowData.id}
                              onConfirmDelete={confirmDeleteDocument}
                            />
                          )}
                        </div>
                      ) : (
                        <div style={contentStyle}>N/A</div>
                      )}
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        onChange={(e) => handleFileUpload(e, "doc1", rowData.id)}
                        className="form-control mt-1"
                      />
                      <input
                        type="file"
                        onChange={(e) => handleFileUpload(e, "doc2", rowData.id)}
                        className="form-control mt-1"
                      />
                    </>
                  )}
                </td>

                <td style={cellStyle}>
                  <strong style={contentStyle}>Receipt Attachment:</strong>
                  {!isEditing ? (
                    <div style={contentStyle}>
                      {rowData.doc3 ? (
                        <div style={contentStyle}>
                          <button
                            className="btn btn-link"
                            onClick={() => handleViewDocument(rowData.doc3)}
                          >
                            View
                          </button>
                          {!rowData.locked && (
                            <DeleteModalButton
                              btnText="Delete"
                              field="doc3"
                              rowId={rowData.id}
                              onConfirmDelete={confirmDeleteDocument}
                            />
                          )}
                        </div>
                      ) : (
                        <div style={contentStyle}>N/A</div>
                      )}
                      {rowData.doc4 ? (
                        <div style={contentStyle}>
                          <button
                            className="btn btn-link"
                            onClick={() => handleViewDocument(rowData.doc4)}
                          >
                            View
                          </button>
                          {!rowData.locked && (
                            <DeleteModalButton
                              btnText="Delete"
                              field="doc4"
                              rowId={rowData.id}
                              onConfirmDelete={confirmDeleteDocument}
                            />
                          )}
                        </div>
                      ) : (
                        <div style={contentStyle}>N/A</div>
                      )}
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        onChange={(e) => handleFileUpload(e, "doc3")}
                        className="form-control mt-1"
                      />
                      <input
                        type="file"
                        onChange={(e) => handleFileUpload(e, "doc4")}
                        className="form-control mt-1"
                      />
                    </>
                  )}
                </td>


                <td style={cellStyle}>
                  <strong style={contentStyle}>Reconcile:</strong>
                  {isEditing ? (
                    <select
                      value={formData.reconcile === 1 ? "Yes" : "No"} // Ensure it's correctly mapped
                      onChange={(e) => {
                        const value = e.target.value === "Yes" ? 1 : 0; // Convert to 1 or 0
                        setFormData({
                          ...formData,
                          reconcile: value,
                          reconcileReviewedBy: value ? userName : "",
                        });
                      }}
                      className="form-control mt-1"
                      disabled={isDisabled}
                    >

                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <div style={contentStyle}>{rowData.reconcile ? "Yes" : "No"}</div>
                  )}
                </td>

                <td style={cellStyle}>
                  <strong style={contentStyle}>Reconcile By:</strong>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.reconcileReviewedBy || ""}
                      onChange={(e) => handleChange(e, "reconcileReviewedBy")}
                      className="form-control mt-1"
                      disabled={true} // Always disabled
                    />
                  ) : (
                    <div style={contentStyle}>{rowData.reconcileReviewedBy || "N/A"}</div>
                  )}
                </td>

                <td style={cellStyle}>
                  <strong style={contentStyle}>Locked:</strong>
                  {isEditing ? (
                    <select
                      value={formData.locked === 1 ? "Yes" : "No"} // Ensure it's correctly mapped
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          locked: e.target.value === "Yes" ? 1 : 0, // Convert to 1 or 0
                        })
                      }
                      className="form-control mt-1"
                      disabled={isDisabled}
                    >

                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  ) : (
                    <div style={contentStyle}>{rowData.locked ? "Yes" : "No"}</div>
                  )}
                </td>

                <td style={cellStyle}>
                  {isEditing ? (
                    <div className="d-flex flex-column align-items-center gap-2">
                      <button className="btn btn-success" onClick={handleSave}>
                        Save
                      </button>
                      <button className="btn btn-secondary" onClick={handleCancel}>
                        Cancel
                      </button>
                    </div>
                  ) : (
                    // Show Edit button only if allowed
                    (rowData.locked ? isAdmin : true) && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleEdit(rowData)}
                      >
                        Edit
                      </button>
                    )
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </td>
      </tr>
    );
  };


  {
    viewModal && (
      <div className="modal show d-block" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View Document</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setViewModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <iframe
                src={currentDocument}
                style={{ width: "100%", height: "400px" }}
                title="Document Viewer"
              ></iframe>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setViewModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <ApprovedModal
        approvedModal={approvedModal}
        NewMessage="Approved Deposit"
        toggle={toggle}
        defaultVal="Default Value"
        setApprovedModal={setApprovedModal}
        approvedData={approvedModalData}
        refreshRecord={getListRecord}
      />
      <RejectModal
        rejectModal={rejectModal}
        NewMessage="Reject Deposit"
        rejecttl={rejecttl}
        defaultVal="Default Value"
        setRejectModal={setRejectModal}
        rejectData={rejectModalData}
        refreshRecord={getListRecord}
      />
      {/* ✅ Add Gateway Modal Here */}
      {gatewayModal && (
        <GatewayModal
          modal={gatewayModal}
          NewMessage="Add Gateway"
          toggle={() => setGatewayModal(false)}
          setModal={setGatewayModal}
          refreshRecord={getListRecord}
        />
      )}
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col md="4">
                <H5>{Deposits}</H5>
              </Col>
              <Col md="8" className="d-flex justify-content-end align-items-center gap-2">
                <input
                  className="form-control w-auto"
                  type="text"
                  value={filter}
                  onChange={handleFilterChange}
                  placeholder="Search..."
                />

                {getMenuPerm(permissions, 'transactions:create') === 1 && (
                  <DepositModalButton btnText="Add" defaultVal="Godo" refreshRecord={getListRecord} />
                )}

                {(roleId === 33 || roleId === 35) &&
                  getMenuPerm(permissions, 'transactions:create') === 1 && (
                    <button className="btn btn-primary" onClick={() => setGatewayModal(true)}>
                      Gateway
                    </button>
                  )
                }

                <Select
                  defaultValue={options.find(option => option.value === limit.toString())}
                  onChange={handleLimitChange}
                  options={options}
                />
              </Col>
            </Row>
          </CardHeader>

          <div className="table-responsive">
            <Table hover striped>
              <thead>
                <tr>
                  <th scope="col">{"#"}</th>
                  <th scope="col">{"Date"}</th>
                  <th scope="col">{"Account"}</th>
                  <th scope="col">{"Name"}</th>
                  <th scope="col">{"TP Amount"}</th>
                  <th scope="col">{"TP Currency"}</th>
                  <th scope="col">{"Req Amount"}</th>
                  <th scope="col">{"Req Currency"}</th>
                  <th scope="col">{"Fee"}</th>
                  <th scope="col">{"Gateway"}</th>
                  <th scope="col">{"Deal Id"}</th>
                  <th scope="col">{"note"}</th>
                  <th scope="col">{"Status"}</th>
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              <tbody>
                {filteredData && filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <Fragment key={index}>
                      <tr>
                        <th scope="row">{item.id}</th>
                        <th scope="row">{formatDate(new Date(item.createdAt))}</th>
                        <td>
                          {item["Account.login"] || `${item["AccountFrom.login"]} -> ${item["AccountTo.login"]}`}
                        </td>
                        <td>
                          {item["Account.Customer.firstName"] + ' ' + (item["Account.Customer.lastName"] || '')}
                        </td>
                        <td>{Math.floor(item.paid)}</td>
                        <td>{item["Account.currency"]}</td>
                        <td>{Math.floor(item.amount)}</td>
                        <td>{item.depositCurrency}</td>
                        <td>{item.fee}</td>
                        <td>{item.gateway}</td>
                        <th scope="row">{item.dealId}</th>
                        <td>{item.note}</td>
                        <td>
                          <TableRowStatus status={item.status} />
                        </td>
                        <td>
                          {item.action}
                          {item.status === 'PENDING' && getMenuPerm(permissions, 'transactions:actions') === 1 && (
                            <Fragment>
                              <a href="#javascript" className="tble_edit">
                                <TableRowApprove onClick={(e) => toggle(e, item)} />
                              </a>
                              <a href="#javascript" className="tble_delete">
                                <TableRowReject onClick={(e) => rejecttl(e, item)} />
                              </a>
                            </Fragment>
                          )}
                          {[33, 35].includes(profile?.Role?.id) && (
                            <a
                              onClick={() => toggleExpandRow(item.id)}
                              style={{
                                color: "#007bff",
                                fontSize: "18px",
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-arrow-down"></i>
                            </a>
                          )}

                        </td>
                      </tr>
                      {expandedRow === item.id && renderSubTable(item)}
                    </Fragment>
                  ))
                ) : (
                  ''
                )}
              </tbody>
            </Table>
            <TablePagination data={rec} loadData={getListRecord} limit={limit} param={appendParameterToUrl} />
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
