import React, { useState, useEffect } from 'react';
import { Table, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { fetchDocuments as fetchdocs, getDocumentSs } from '../../../Services/managedocs.service';
import { toast } from 'react-toastify';

const HRpolicy = () => {
    const [documents, setDocuments] = useState([]);

    // Fetch all documents when the component mounts
    const fetchDocuments = async () => {
        try {
            const data = await fetchdocs();
            console.log('Documents...', data);
            // Filter documents to only include those from userId 151
            const filteredDocs = data.filter(doc => doc.userId === '151');
            setDocuments(filteredDocs);
        } catch (error) {
            console.error('Failed to load documents:', error);
        }

    };

    useEffect(() => {
        fetchDocuments();
    }, []);


    const getUrl = async (item) => {
        try {
            const res = await getDocumentSs(item, item.id);
            console.log('Response from getDocumentSs:', res);

            if (res && res.url) {
                window.open(res.url, '_blank');
            } else {
                toast.error('Failed to generate Link: No URL returned');
            }
        } catch (err) {
            console.error('Error generating link:', err);
            toast.error('Failed to generate Link');
        }
        return false;
    };


    return (
        <Card>
            <CardHeader>
                <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>
                    HR Policies
                </h3>
            </CardHeader>
            <CardBody>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Document Name</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map((document) => (
                            <tr key={document.id}>
                                <td>{document.docName}</td>
                                <td>
                                    <Button color="primary" onClick={() => getUrl(document.id)}>
                                        View
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default HRpolicy;
