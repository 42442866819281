import React, { useEffect, useState } from "react";
import { Card, CardBody, Button, Input } from "reactstrap";
import { Line } from "react-chartjs-2";
import { MarketReport } from "Services";
import { getDocumentSas } from "Services/usersPersonalDetail.service"; // API to fetch images
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from "chart.js";
import Image1 from "../../../assets/images/godounknowpic.svg"; // Local fallback image
import PhoneIcon from "../../../assets/images/Phone.svg";
import MailIcon from "../../../assets/images/mail.svg";
import BlueProfile from "../../../assets/images/blur-profile.svg";
import YellowProfile from "../../../assets/images/yellow-profile.svg";
import GreenTrade from "../../../assets/images/green-trade.svg";
import { useLocation } from "react-router-dom";

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

function Sales() {

  // Inside the Sales component:
  const location = useLocation();
  const [selectedAgent, setSelectedAgent] = useState(location.state?.selectedAgent || null);

  // const [selectedAgent, setSelectedAgent] = useState(null);
  const [groupedSalesData, setGroupedSalesData] = useState({});
  const [agentImages, setAgentImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);



  const totalDeposit = reportData
    .filter(entry => entry.SalesManager === selectedAgent?.SalesManager)
    .reduce((sum, entry) => sum + (entry.Deposit || 0), 0);

  const totalConvertedClients = reportData
    .filter(entry =>
      entry.SalesManager?.trim() === selectedAgent?.SalesManager?.trim() &&
      entry.Converted?.toString().toLowerCase() === "true"
    ).length;

  const statusCounts = reportData
    .filter(entry => entry.SalesManager?.trim() === selectedAgent?.SalesManager?.trim()) // Filter by SalesManager
    .reduce((acc, entry) => {
      const status = entry.Status || "Unknown"; // Handle missing status
      acc[status] = (acc[status] || 0) + 1; // Count occurrences
      return acc;
    }, {});


  const SalesChart = ({ agent }) => {
    const filteredSalesData = reportData
      .filter(entry =>
        entry.SalesManager?.trim() === agent?.SalesManager?.trim() &&
        entry.Converted?.toString().toLowerCase() === "true" &&
        entry.Deposit > 0
      );

    // Extract deposits over time
    const salesDates = filteredSalesData.map(entry => new Date(entry.Created).getDate());
    const depositAmounts = filteredSalesData.map(entry => entry.Deposit);

    // Chart data
    const salesData = {
      labels: salesDates.map(day => `${day} Dec`),
      datasets: [
        {
          label: "Deposit Amount",
          data: depositAmounts,
          borderColor: "#00BAFF",
          backgroundColor: "rgba(0, 123, 255, 0.2)",
          fill: true,
        },
      ],
    };

    return (
      <Card className="mt-6 p-6 shadow-md">
        <h4 className="text-lg font-bold mb-4" style={{ color: "#202224" }}>
          Sales Deposits (Converted Clients)
        </h4>
        <Line data={salesData} />
      </Card>
    );
  };

  useEffect(() => {
    const fetchMarketReport = async () => {
      try {
        const response = await MarketReport.fetchMarketReport();
        if (Array.isArray(response)) {
          const filtered = response.filter(entry =>
            entry.Converted?.toString().toLowerCase() === "true" &&
            entry.Deposit > 0
          );
          setReportData(filtered); // Only store filtered data
          setFilteredData(filtered);
        } else {
          console.warn("Unexpected API response:", response);
        }
      } catch (error) {
        console.error("Error fetching market report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMarketReport();
  }, []);


  // Sales Details Chart Data
  const salesData = {
    labels: Array.from({ length: 30 }, (_, i) => `${i + 1} Dec`),
    datasets: [
      {
        label: "Sales Performance",
        data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 100)),
        borderColor: "#007bff",
        backgroundColor: "rgba(0, 123, 255, 0.2)",
        fill: true,
      },
    ],
  };


  // Function to Fetch Image using getUrl
  const getUrl = async (pictureId) => {
    try {
      const res = await getDocumentSas(pictureId);
      // console.log("Response from getDocumentSas:", res);

      if (res && res.url) {
        return res.url; // Return the URL to be used for the image
      } else {
        return Image1; // Use local fallback image
      }
    } catch (err) {
      console.error("Error fetching image:", err);
      return Image1; // Return local fallback image on failure
    }
  };

  // Fetch and Group Data by SalesId
  useEffect(() => {
    const fetchAndGroupSalesData = async () => {
      try {
        const response = await MarketReport.fetchMarketReport();

        let salesData;
        if (Array.isArray(response)) {
          salesData = response; // API returned an array directly
        } else if (response.status && Array.isArray(response.result)) {
          salesData = response.result; // API returned expected {status, result}
        } else {
          console.warn("Unexpected API response format:", response);
          return;
        }

        const salesGroup = {};

        for (const lead of salesData) {
          const { SalesId, SalesManager, SalesJobTitle, pictureId, SalesMno, SalesEmail } = lead;

          if (!salesGroup[SalesId]) {
            salesGroup[SalesId] = {
              SalesManager,
              SalesJobTitle,
              pictureId,
              totalLeads: 0,
              SalesEmail,
              SalesMno,
            };
          }

          salesGroup[SalesId].totalLeads += 1;
        }

        setGroupedSalesData(salesGroup);
      } catch (error) {
        console.error("Error fetching market report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndGroupSalesData();
  }, []);

  // Fetch Profile Images for Each pictureId
  useEffect(() => {
    const fetchAgentImages = async () => {
      const imageUrls = {};

      await Promise.all(
        Object.values(groupedSalesData).map(async (agent) => {
          const url = await getUrl(agent.pictureId); // Use getUrl() to fetch image
          imageUrls[agent.pictureId] = url;
        })
      );

      setAgentImages(imageUrls);
    };

    if (Object.keys(groupedSalesData).length > 0) {
      fetchAgentImages();
    }
  }, [groupedSalesData]);

  // Fetch data
  useEffect(() => {
    const fetchMarketReport = async () => {
      try {
        const response = await MarketReport.fetchMarketReport();
        if (Array.isArray(response)) {
          setReportData(response);
          setFilteredData(response); // Initial load
        } else {
          console.warn("Unexpected API response:", response);
        }
      } catch (error) {
        console.error("Error fetching market report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMarketReport();
  }, []);


  return (
    <div style={{ padding: "20px" }}>
      <style>{`.form-group.w-75 { display: none; } .form-inline.search-fulls.col-7 { border: none; } .MuiBox-root.css-19midj6 { padding: 3px; } .border-left { border-left-width: 4px; }`}</style>

      {/* Show Agent Profile when selected */}
      {selectedAgent ? (
        <div>
          {/* Agent Profile Card */}
          <Card className="p-6 mb-6 shadow-lg rounded-[16px]  bg-white" style={{ padding: "20px", margin: "auto", borderRadius: "16px" }}>
            <div className="flex items-center mb-4">
              <img
                src={agentImages[selectedAgent?.pictureId] || Image1}
                alt={selectedAgent?.SalesManager || "Agent Image"}
                onClick={() => setSelectedAgent(null)}
                className="w-14 h-14 rounded-full mr-4 object-cover cursor-pointer"
                title="Click to return to the list."
                style={{
                  width: "87.1px",
                  height: "87.1px",
                  borderRadius: "50%",
                  border: "3px solid #ddd",
                  objectFit: "cover",
                }}
              />

              <div className="mt-4 flex justify-between" style={{ width: "87%" }}>
                <div>
                  <h4 className="text-xl font-bold" style={{ color: "#2C323F" }}>{selectedAgent?.SalesManager}</h4>
                  <p className="text-gray-600" style={{ color: "#979797" }}>{selectedAgent?.SalesJobTitle}</p>
                </div>
                <div className="flex items-center gap-4">
                  <span className="flex items-center gap-2">
                    <img src={PhoneIcon} alt="Phone Icon" width="20" height="20" className="object-contain" />
                    {selectedAgent?.SalesMno}
                  </span>
                  <span className="flex items-center gap-2">
                    <img src={MailIcon} alt="Mail Icon" width="20" height="20" className="object-contain" />
                    <a href={`mailto:${selectedAgent?.SalesEmail}`} className="text-blue-600">{selectedAgent?.SalesEmail}</a>
                  </span>
                </div>
              </div>
            </div>


            {/* Stats Row */}
            <div className="flex justify-between  mb-4">
              <div className="flex items-center justify-between  rounded-[14.03px] shadow-md p-4" style={{ width: "365px", height: "123px", backgroundColor: "#F7F8F9", borderRadius: "14.03px" }}>
                <div style={{ width: "60%" }}>
                  <p className="text-gray-500" style={{ fontSize: "16.03px", color: "#202224" }}>Total Leads</p>
                  <p className="text-2xl font-bold" style={{ color: "#202224", fontSize: "28.06px" }}>{selectedAgent.totalLeads}</p>
                </div>
                <div style={{ width: "40%", display: "flex", justifyContent: "flex-end" }}>
                  <img src={BlueProfile} alt="Mail Icon" width="56" height="56" className="object-contain" />
                </div>
              </div>

              <div className="flex items-center justify-between  rounded-[14.03px] shadow-md p-4" style={{ width: "365px", height: "123px", backgroundColor: "#F7F8F9", borderRadius: "14.03px" }}>
                <div style={{ width: "60%" }}>
                  <p className="text-gray-500" style={{ fontSize: "16.03px", color: "#202224" }}>Converted Clients</p>
                  <p className="text-2xl font-bold" style={{ color: "#202224", fontSize: "28.06px" }}>{totalConvertedClients}</p>
                </div>
                <div style={{ width: "40%", display: "flex", justifyContent: "flex-end" }}>
                  <img src={YellowProfile} alt="Mail Icon" width="56" height="56" className="object-contain" />
                </div>
              </div>

              <div className="flex items-center justify-between  rounded-[14.03px] shadow-md p-4" style={{ width: "365px", height: "123px", backgroundColor: "#F7F8F9", borderRadius: "14.03px" }}>
                <div style={{ width: "60%" }}>
                  <p className="text-gray-500" style={{ fontSize: "16.03px", color: "#202224" }}>Deposit</p>
                  <p className="text-2xl font-bold" style={{ color: "#202224", fontSize: "28.06px" }}>
                    ${totalDeposit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </p>

                </div>
                <div style={{ width: "40%", display: "flex", justifyContent: "flex-end" }}>
                  <img src={GreenTrade} alt="Mail Icon" width="56" height="56" className="object-contain" />
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-2 mb-4 mt-auto overflow-auto max-h-40 md:max-h-52 lg:max-h-full" style={{ height: "auto", marginTop:"60px " }}>
              {Object.entries(statusCounts).map(([status, count], index) => {
                const colors = ["#DE0D92", "#f28b82", "#fbbc04", "#34a853", "#4285f4", "#a142f4", "#ff6d00"];
                const color = colors[index % colors.length]; // Assign colors dynamically

                return (
                  <button
                    key={status}
                    className="px-4 py-2 text-sm font-semibold rounded-lg"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "100px",
                      maxWidth: "fit-content",
                      height: "34px",
                      padding: "0 12px",
                      border: `1px solid ${color}`,
                      color,
                      backgroundColor: `${color}1A`,
                      whiteSpace: "nowrap"
                    }}
                  >
                    {`${status} (${count})`}
                  </button>
                );
              })}
            </div>

          </Card>

          <Card className="p-6 mb-6 shadow-lg rounded-[16px] bg-white" style={{ padding: "20px", marginTop: "20px", borderRadius: "16px" }}>
            {/* Sales Chart Card - Displayed Separately */}
            <SalesChart agent={selectedAgent} />
          </Card>

        </div>
      ) : (
        // Sales List (Only visible when no agent is selected)
        <Card className="p-6 mb-6" style={{ marginTop: "20px", borderRadius: "24px", marginBottom: "30px" }}>
          <div style={{ padding: "26px" }}>
            {/* Header Section */}
            <div className="flex justify-between items-center">

            </div>

            {/* Agents Row */}
            {loading ? (
              <p>Loading sales data...</p>
            ) : Object.keys(groupedSalesData).length === 0 ? (
              <p>No sales data available.</p>
            ) : (
              <div className="flex flex-wrap gap-4">
                {Object.values(groupedSalesData).map((data) => (
                  <Card

                    className="p-4 text-center shadow-md bg-gray-100 rounded-lg flex-shrink-0"
                    style={{
                      background: "#F7F8F9",
                      width: "calc(100% / 4 - 16px)",
                      maxWidth: "226px",
                      minWidth: "180px",
                    }}
                  >
                    <CardBody>
                      {/* Agent Info */}
                      <img
                        src={agentImages[data.pictureId] || Image1} // Display fetched image or local fallback
                        alt={data.SalesManager}
                        className="w-16 h-16 rounded-full mx-auto mb-2 object-cover"
                        style={{
                          width: "87.1px",
                          height: "87.1px",
                          borderRadius: "50%",
                          border: "3px solid #ddd",
                          objectFit: "cover",
                        }}

                      />
                      <p style={{ fontSize: "15px", color: "#2C323F", marginBottom: "0px" }}>{data.SalesManager}</p>
                      <p style={{ fontSize: "10.56px", color: "#979797" }}>{data.SalesJobTitle}</p>

                      {/* View Profile Button */}
                      <button
                        onClick={() => setSelectedAgent(data)}
                        style={{
                          background: "#E6E6E6",
                          color: "#898989",
                          border: "none",
                          height: "30px",
                          width: "120px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        View Profile
                      </button>
                    </CardBody>
                  </Card>
                ))}
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  );
}

export default Sales;
