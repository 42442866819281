import api from './api';

export const getCampaignsStatus = () => {
  return api.get('/v1/crm/CampaignsStatus/');
};

export const createCampaignsStatus = (data) => {
  return api.post('/v1/crm/CampaignsStatus/', data);
};

export const deleteCampaignsStatus = (id) => {
  return api.delete(`/v1/crm/CampaignsStatus/${id}`);
};

export const updateCampaignsStatus = (id, updatedData) => {
  return api.put(`/v1/crm/CampaignsStatus/${id}`, updatedData);
};
