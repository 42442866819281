import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  TextField,
  Grid,
} from "@mui/material";
import { OverallReport } from "Services/OverallReport.service";
import { Tooltip } from "@mui/material";
import * as XLSX from "xlsx";


function OverviewReport() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "createdAt", direction: "desc" });
  const [isLoading, setIsLoading] = useState(true);
  const CustomTablePagination = (props) => (
        <TablePagination
          {...props}
          sx={{
            '& .MuiTablePagination-selectLabel': {
              marginBottom: 0,
            },
            '& .MuiTablePagination-displayedRows': {
              marginBottom: 0,
            },
          }}
        />
      );
  

      const handleExportToExcel = () => {
        // Extract only the required columns
        const exportData = filteredData.map((row) => {
          const formattedRow = {};
          columns.forEach((col) => {
            formattedRow[col.label] = row[col.id] ?? ""; // Set empty string if value is undefined
          });
          return formattedRow;
        });
      
        // Create worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overview Report");
      
        // Generate filename with date and time
        const now = new Date();
        const timestamp = now
          .toISOString()
          .replace(/T/, "_") // Replace T with _
          .replace(/:/g, "-") // Replace : with - for filename compatibility
          .split(".")[0]; // Remove milliseconds
      
        const filename = `Overview_Report_${timestamp}.xlsx`;
      
        // Save the file
        XLSX.writeFile(workbook, filename);
      };
      
      

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await OverallReport();
        if (response) {
          setData(response);
          setFilteredData(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let filtered = data;
  
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) =>
            value && value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }
  
    if (startDate || endDate) {
      filtered = filtered.filter((item) => {
        const itemDate = new Date(item.createdAt);
        
        // Ensure startDate is set to 00:00:00
        const startDateTime = startDate
          ? new Date(startDate + "T00:00:00").getTime()
          : null;
  
        // Ensure endDate is set to 23:59:59
        const endDateTime = endDate
          ? new Date(endDate + "T23:59:59").getTime()
          : null;
  
        return (
          (!startDateTime || itemDate.getTime() >= startDateTime) &&
          (!endDateTime || itemDate.getTime() <= endDateTime)
        );
      });
    }
  
    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);
  
  
  const handleSort = (column) => {
    const isAsc = sortConfig.key === column && sortConfig.direction === "asc";
    setSortConfig({ key: column, direction: isAsc ? "desc" : "asc" });
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return filteredData;
    return [...filteredData].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
  
      // Handle date sorting separately
      if (sortConfig.key === "createdAt") {
        return sortConfig.direction === "asc"
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }
  
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortConfig]);
  

  const paginatedData = React.useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { id: "SalesPersonName", label: "Sales", width: "130px" },
    { 
      id: "isib", 
      label: "Type", 
      width: "120px",
      getCellStyle: (value) => ({
        color: 
          value === "Direct" ? "green" :
          value === "IB" ? "orange" :
          value === "Rebate" ? "blue" :
          "inherit",
      })
    },
    { id: "GroupType", label: "Book", width: "120px" },
    { id: "IBName", label: "IB Name", width: "130px" },
    { id: "fullName", label: "Client", width: "150px" },
    { id: "login", label: "A/C", width: "100px" },
    { id: "Deposit", label: "Deposit", width: "100px" },
    { 
      id: "InternalDeposit", 
      label: "Internal Credit", 
      width: "130px",
      getCellStyle: () => ({ color: "red" }) // Apply red text color
    },
    { id: "TotalDeposit", label: "Total Deposit", width: "130px" },
    { id: "Withdraw", label: "Withdraw", width: "130px" }, 
    { 
      id: "InternalWithdraw", 
      label: "Internal Debit", 
      width: "130px",
      getCellStyle: () => ({ color: "red" }) // Apply red text color
    },
    { id: "TotalWithdraw", label: "Total Withdraw", width: "130px" },
    { 
      id: "NetDeposit", 
      label: "Net Deposit", 
      width: "130px",
      getCellStyle: () => ({ color: "green" }) // Apply green text color
    },
    { id: "CREDIT", label: "Credit", width: "110px" },
    { id: "EQUITY", label: "Equity", width: "110px" },
    { id: "Commission", label: "Commission", width: "110px" },
    { id: "Swap", label: "Swap", width: "80px" }, 
    { id: "TotalCommission", label: "RProfit", width: "80px" },
    { id: "lot", label: "Lot", width: "120px" },
    { id: "rebate", label: "Rebate", width: "100px" },  
    { id: "ibType", label: "Contract Type", width: "120px" }, 
    { id: "deal", label: "Contract Deal %", width: "120px" }, 
    { id: "AccountGroup", label: "Account Group", width: "130px" },
    { id: "BALANCE", label: "Balance", width: "110px" },
    { id: "currency", label: "Currency", width: "80px" },
    { id: "ClosedPL", label: "Closed P/L", width: "110px" },
    { id: "platform", label: "Platform", width: "120px" },
    { id: "AccountType", label: "Account Type", width: "150px" },  
    { id: "createdAt", label: "Created At", width: "130px" },
  
  ];
  

  return (
    <div>
       <style>
        {`
          .form-group.w-75 {
            display: none;
        
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }

              .MuiBox-root.css-19midj6 {
                  padding: 3px;
                }
        `}
      </style>
       {/* Adding the new div before the SalesReports content */}
       <div style={{ height: "20px" }} className="container-fluid">
        <div className="page-title" style={{ display: "none" }}>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

 {/* Search and Date Filters */}
<Grid container spacing={2} style={{ marginBottom: "20px" }}>
  <Grid item xs={12} md={2}>
    <TextField
      placeholder="Search"
      variant="outlined"
      fullWidth
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        style: {
          height: "38px",
          padding: "0 14px",
          display: "flex",
          alignItems: "center",
        },
      }}
    />
  </Grid>
  <Grid item xs={12} md={2}>
    <TextField
      placeholder="Start Date"
      type="date"
      fullWidth
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
      InputProps={{
        style: {
          height: "38px",
          padding: "0 14px",
          display: "flex",
          alignItems: "center",
        },
      }}
    />
  </Grid>
  <Grid item xs={12} md={2}>
    <TextField
      placeholder="End Date"
      type="date"
      fullWidth
      value={endDate}
      onChange={(e) => setEndDate(e.target.value)}
      InputProps={{
        style: {
          height: "38px",
          padding: "0 14px",
          display: "flex",
          alignItems: "center",
        },
      }}
    />
  </Grid>
  <Grid item xs={12} md={2}>
    <button
      onClick={handleExportToExcel}
      style={{
        height: "38px",
        padding: "8px 16px",
        backgroundColor: "#007bff",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "bold",
      }}
    >
      Export
    </button>
  </Grid>
</Grid>


      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <Paper>
<TableContainer component={Paper}>
  <Table sx={{ tableLayout: "fixed", width: "100%" }}>
    <TableHead>
    <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            sx={{
              width: column.width, // Apply custom width
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <TableSortLabel
              active={sortConfig.key === column.id}
              direction={sortConfig.key === column.id ? sortConfig.direction : "asc"}
              onClick={() => handleSort(column.id)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {paginatedData.map((row, index) => (
        <TableRow key={index}>
          {columns.map((column) => (
            <TableCell
            key={column.id}
            sx={{
              width: column.width, 
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
              ...(column.getCellStyle ? column.getCellStyle(row[column.id]) : {}),
            }}
          >
         <Tooltip
          title={
            <Typography sx={{ fontSize: "1rem", padding: "6px 12px" }}>
              {row[column.id] ?? ""}
            </Typography>
          }
          arrow
          placement="top"
          enterDelay={300}
          leaveDelay={100}
        >
          <span style={{ display: "block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {column.id === "createdAt"
              ? new Date(row[column.id]).toLocaleDateString()
              : column.id === "Commission"
              ? Math.floor(row[column.id] ?? 0)
              : row[column.id] ?? '0'}
          </span>
        </Tooltip>
          </TableCell>
          
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
<CustomTablePagination
  rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} // Custom rows per page options
  component="div"
  count={filteredData.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>

        </Paper>
      )}
    </div>
  );
}

export default OverviewReport;
