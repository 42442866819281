import api from './api';

const documentsTransactionService = {
    /**
     * Fetch the list of document transactions
     * @returns {Promise} API response
     */
    getListingTransaction: () => {
        return api.get('/v1/crm/document-transactions'); // Matches the backend router's `GET /` endpoint
    },

    /**
     * Add a new document transaction
     * @param {FormData} formData - The form data containing files and fields
     * @returns {Promise} API response
     */
    addRecordTransaction: (formData) => {
        return api.post('/v1/crm/document-transactions', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};

export const addRecordTransaction = (formData) => {
    return api.post('/v1/crm/documentTransaction', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}


export default documentsTransactionService;
