import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { Grid } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const InActiveIBReport = ({ searchTerm, startDate, endDate, showCharts }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupMembers, setGroupMembers] = useState([]); // State for group members

  const profile = useSelector((state) => state.login?.profile);
  const roleId = profile?.roleId || null;
  const userId = profile?.id || null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
  };

  const columns = [
    { name: "Created Date", selector: (row) => formatDate(row.CreatedDate), sortable: true },
    { name: "IB ID", selector: (row) => row.IBID, sortable: true },
    { name: "IB Name", selector: (row) => row.IBName, sortable: true },
    { name: "Sales", selector: (row) => row.Sales, sortable: true },
    { name: "Rebate", selector: (row) => `$${Number(row.OutStandingRebate || 0).toLocaleString()}`, sortable: true },
    { name: "Payout", selector: (row) => `$${Number(row.Payout || 0).toLocaleString()}`, sortable: true },
    { name: "Client", selector: (row) => row.ClientCount, sortable: true },
  ];

  useEffect(() => {
    if (userId) {
      const fetchGroupMembers = async () => {
        try {
          const response = await SalesReportService.getGroupMembersById(userId);
          setGroupMembers(response);
        } catch (err) {
          console.error("Failed to fetch group members:", err);
          toast.error("Failed to fetch group members.");
          setGroupMembers([]);
        }
      };
      fetchGroupMembers();
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await SalesReportService.getInActiveIB();

         // Filter data based on role and group members
            let filteredResponse = response;
          
    
            // If the role is not in the specified ones and groupMembers is not empty, filter by group members
            if (roleId !== 33 && roleId !== 18 && roleId !== 41) {
              if (groupMembers.length > 0) {
                // Filter deposits by matching agentId with group members
                filteredResponse = response.filter((item) =>
                  groupMembers.includes(item.agentId)
                );
              } else {
                // If no group members are found, filter by userId
                filteredResponse = response.filter((item) => item.agentId === userId);
              }
            }
    
            setData(filteredResponse);
            setFilteredData(filteredResponse);
          } catch (err) {
            setError("Failed to fetch data.");
            toast.error("Failed to fetch data.");
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, [roleId, userId, groupMembers]);

  useEffect(() => {
    let filtered = data;
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) => value && value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }
    if (startDate) {
      filtered = filtered.filter((item) => new Date(item.CreatedDate) >= new Date(startDate));
    }
    if (endDate) {
      filtered = filtered.filter((item) => new Date(item.CreatedDate) <= new Date(endDate));
    }
    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);

  // Prepare the data for the Bar Chart
  const getBarChartData = () => {
    const ibData = filteredData.reduce((acc, item) => {
      const ibName = item.IBName; // Correct IB Name reference

      if (!acc[ibName]) {
        acc[ibName] = {
          rebate: 0,
          payout: 0,
          client: 0,
        };
      }

      acc[ibName].rebate += item.OutStandingRebate || 0;
      acc[ibName].payout += item.Payout || 0;
      acc[ibName].client += item.ClientCount || 0;

      return acc;
    }, {});

    const labels = Object.keys(ibData);
    return {
      labels: labels,
      datasets: [
        {
          label: "Rebate",
          data: labels.map((ibName) => ibData[ibName].rebate), // Corrected data reference
          backgroundColor: "#086CA2",
          borderColor: "#086CA2",
          borderWidth: 1,
          categoryPercentage: 0.5,
          barPercentage: 0.8,
        },
        {
          label: "Payout",
          data: labels.map((ibName) => ibData[ibName].payout), // Corrected data reference
          backgroundColor: "#FF5733",
          borderColor: "#FF5733",
          borderWidth: 1,
          categoryPercentage: 0.5,
          barPercentage: 0.8,
        },
        {
          label: "Client",
          data: labels.map((ibName) => ibData[ibName].client), // Corrected data reference
          backgroundColor: "#28A745",
          borderColor: "#28A745",
          borderWidth: 1,
          categoryPercentage: 0.5,
          barPercentage: 0.8,
        },
      ],
    };
  };

  return (
    <div style={{ position: "relative" }}>
      {error && <p className="text-danger">{error}</p>}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : filteredData.length > 0 ? (
        <Grid container spacing={1}>
          {showCharts && (
            <Grid item xs={12} md={9}>
              <div style={{ marginBottom: "20px", width: "100%" }}>
                <Bar
                  data={getBarChartData()}
                  options={{
                    responsive: true,
                    scales: {
                      x: { stacked: true },
                      y: { stacked: true },
                    },
                  }}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <DataTable
              columns={columns}
              data={filteredData || []}
              pagination
              striped
              persistTableHead
            />
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          No data available.
        </div>
      )}
    </div>
  );
};

export default InActiveIBReport;
