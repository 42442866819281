import React, { Fragment, useEffect, useState } from 'react';
import { clientService } from 'Services';
import { Table, Row, Col } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
// import LinkClient from './LinkClient';
import { useSelector } from "react-redux";
// import { getMenuPerm } from 'utils';
import WithoutFilterPagination from "CommonElements/Table/WithoutFilterPagination";
// import { toast } from 'react-toastify';
// import Select from 'react-select';
import { Link } from 'react-router-dom';

const IbClients = (props) => {
    const {
        customerId = '',
        data = '',
        activeTab, tabNumber
    } = props;
    const [rec, setRec] = useState({});
    const [limit, setLimit] = useState(10);
    useEffect(() => {
        if (activeTab === tabNumber) {
            loadData();
        }
    }, [activeTab, tabNumber]);

    const loadData = (page = 1, limit) => {
        clientService.getClientsbyQuery({ referrar: customerId, page }).then((res) => {
            setRec({
                ...res,
                loading: false,
            })
            console.log('Refferar Clients are ', res);
        }).catch(err => {
            console.log('Referrar Clients Fetch Error ', err);
        });
    }
    const getPageName = (item) => {
        if (item.isIb) return 'ibs';
        if (item.isClient || item.isCorporate) return 'clients';
        return 'leads';
    }

    return (
        <Fragment>

            <div className="table-responsive">
                <Table hover>
                    <thead>
                        <tr>
                            <th scope="col">{'#'}</th>
                            <th scope="col">{'First Name'}</th>
                            <th scope="col">{'Last Name'}</th>
                            <th scope="col">{'Email'}</th>
                            <th scope="col">{'Client Type'}</th>
                            <th scope="col">{'Phone'}</th>
                            <th scope="col">{'Country'}</th>
                            <th scope="col">{'Registered Date'}</th>

                        </tr>
                    </thead>
                    {rec.loading && <TableLoader />}
                    {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="Referrar Clients" />}

                    <tbody>
                        {
                            rec.data && rec.data.map((item, id) =>
                                <tr key={id}>
                                    <th scope="row">{item.id}</th>
                                    <td >{item.firstName}</td>
                                    <td >{item.lastName ? item.lastName : ''}</td>
                                    <td >
                                        <Link to={`${process.env.PUBLIC_URL}/${getPageName(item)}/${item.id}`}>{item.email}</Link>
                                    </td>
                                    <td >
                                        {item.isLead === 1 && 'Lead'}
                                        {item.isClient === 1 && ' Client'}
                                        {item.isIb === 1 && ' Partner'}
                                        {item.isCorporate === 1 && ' Corporate'}
                                    </td>
                                    <td >{item.phone}</td>
                                    <td >{item.country}</td>
                                    <td >{item.createdAt}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <WithoutFilterPagination data={rec} loadData={loadData} limit={limit} />

            </div>
        </Fragment>
    );
};
export default IbClients;