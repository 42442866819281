import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2"; // Import Doughnut chart from react-chartjs-2
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { Grid } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const OrganicLeadsReport = ({ searchTerm, startDate, endDate, showCharts }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupMembers, setGroupMembers] = useState([]); // State for group members

  // Access roleId and userId from Redux store
  const profile = useSelector((state) => state.login && state.login.profile);
  const roleId = profile?.roleId || null;
  const userId = profile?.id || null;

  // Format the date for display
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
  };

  // Define table columns
  const columns = [
    {
      name: "Created Date",
      selector: (row) => formatDate(row.CreatedDate),
      sortable: true,
    },
    { name: "IB", selector: (row) => row.IB, sortable: true },
    { name: "Sales", selector: (row) => row.Sales, sortable: true },
    { name: "Name", selector: (row) => row.Name, sortable: true },
    { name: "Country", selector: (row) => row.Country, sortable: true },
    { name: "Status", selector: (row) => row.Status, sortable: true },
    { name: "Days", selector: (row) => row.Days, sortable: true },
  ];

  // Fetch group members by userId
  useEffect(() => {
    if (userId) {
      const fetchGroupMembers = async () => {
        try {
          const response = await SalesReportService.getGroupMembersById(userId);
          setGroupMembers(response); // Store the group members
        } catch (err) {
          console.error("Failed to fetch group members:", err);
          toast.error("Failed to fetch group members.");
          setGroupMembers([]); // Reset group members if the API fails
        }
      };

      fetchGroupMembers();
    }
  }, [userId]);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(""); // Reset error state

      try {
        const response = await SalesReportService.getOrganicLeads();

        // Filter data based on role and group members
        let filteredResponse = response;

        // If the role is not in the specified ones and groupMembers is not empty, filter by group members
        if (roleId !== 33 && roleId !== 18 && roleId !== 41) {
          if (groupMembers.length > 0) {
            // Filter deposits by matching agentId with group members
            filteredResponse = response.filter((item) =>
              groupMembers.includes(item.agentId)
            );
          } else {
            // If no group members are found, filter by userId
            filteredResponse = response.filter((item) => item.agentId === userId);
          }
        }

        setData(filteredResponse);
        setFilteredData(filteredResponse);
      } catch (err) {
        setError("Failed to fetch data.");
        toast.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [roleId, userId, groupMembers]);

  // Apply search term and date range filtering
  useEffect(() => {
    let filtered = [...data];

    // Apply search term filter
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }

    // Apply start date filter
    if (startDate) {
      filtered = filtered.filter(
        (item) => new Date(item.CreatedDate) >= new Date(startDate)
      );
    }

    // Apply end date filter
    if (endDate) {
      filtered = filtered.filter(
        (item) => new Date(item.CreatedDate) <= new Date(endDate)
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);

  // Prepare the data for the Doughnut Chart
  const getDoughnutChartData = () => {
    const statusCounts = filteredData.reduce(
      (acc, item) => {
        acc[item.Status] = (acc[item.Status] || 0) + 1;
        return acc;
      },
      {}
    );

    return {
      labels: Object.keys(statusCounts),
      datasets: [
        {
          data: Object.values(statusCounts),
          backgroundColor: ["#AB8FDA", "#87C1F5", "#80D5BE", "#F9C62B", "#9B9FA7", "#E993B6", "#F89D81", "#8CC540", "#9B9FA7", "#A6816A"], // Customize colors
          hoverBackgroundColor: ["#AB8FDA", "#87C1F5", "#80D5BE", "#F9C62B", "#9B9FA7", "#E993B6", "#F89D81", "#8CC540", "#9B9FA7", "#A6816A"],
        },
      ],
    };
  };

  const chartOptions = {
    plugins: {
      legend: { position: 'right' },

    },
  };

  return (
    <div style={{ position: "relative" }}>
      {error && <p className="text-danger">{error}</p>}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : filteredData.length > 0 ? (
        <Grid container spacing={2}>
          {/* Conditionally render the Doughnut chart and Bar chart based on showCharts */}
          {showCharts && (
            <>
              <Grid item xs={12} md={1}>

              </Grid>
              <Grid item xs={12} md={4}>
                <div style={{ marginBottom: "20px", width: "100%" }}>
                  <Doughnut data={getDoughnutChartData()} options={chartOptions} />
                </div>
              </Grid>
            </>
          )}
          {/* DataTable */}
          <Grid item xs={12} md={12}>
            <DataTable
              columns={columns}
              data={filteredData || []}
              pagination
              striped
              persistTableHead
            />
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          No data available.
        </div>
      )}
    </div>
  );
};

export default OrganicLeadsReport;
