import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import ClientsList from './ClientsLists';
import { Breadcrumbs } from '../../../AbstractElements';

const BasicTables = () => {
  return (
    <Fragment>
      <Breadcrumbs title='Corporates Clients' mainTitle='' />
      <Container fluid={true}>
        <Row>
          <ClientsList />
        </Row>
      </Container>
    </Fragment>
  );
};

export default BasicTables;
