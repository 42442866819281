import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FTextArea, FSelect } from 'CommonElements/Form';
// import gateways from '../../Common/Component/Gateways';
import Swal from "sweetalert2";

import CommonModal from '../../../../src/Components/Common/Data/Ui-kits/Modals/common/modal';
import { transactionsService, BankAccount, gatewayService} from 'Services';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

import { ModalFooter } from 'reactstrap';
import { Close, SaveChanges } from '../../../Constant';



const DepositFormModal = (
  { modal, NewMessage, toggle, setModal, refreshRecord },
  props
) => {
  const { data = {} } = props;
  const [accountData, setAccountData] = useState('');
  const [isAccounInputDisable, setIsAccounInputDisable] = useState(false);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });

  const [gatewayOptions, setGatewayOptions] = useState([]);


  // useEffect(() => {
  //   if (gatewayOptions.length > 0) {
  //     setValue("gateway", gatewayOptions[0].value);
  //   }
  // }, [gatewayOptions]);
  

  useEffect(() => {
    gatewayService.getGateWay()
      .then((response) => {
       
        if (response) {
          const filteredGateways = response
            .filter(item => item.category === 'deposit')
            .map(item => ({ name: item.gatewayName, value: item.gatewayName }));
          
          setGatewayOptions([...filteredGateways]); // Ensures re-render
        }
      })
      .catch((error) => {
        console.error("Error fetching gateways:", error);
      });
  }, []);

  

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
  } = useForm();
  const formValueChange = (name, value) => {
    setValue(name, value);
  };
  const resetForm = () => {
    return reset({
      login: '',
      accountTypeId: '',
      gateway: '',
      amount: '',
      reason: '',
      fee: '',
      note: '',
    });
  };
  useEffect(() => {
    if (!modal) {
      clearErrors();
      resetForm();
      setIsAccounInputDisable(false);
    }
  }, [modal]);
  const handleLoginChange = (e) => {
    e.preventDefault();
    const loginNos = e.target.value;
    setValue('login', loginNos);
    setTimeout(startSearch, 3000);
  };
  const startSearch = () => {
    const formFields = getValues();
    var loginNo = formFields.login;
    if (loginNo != null && loginNo !== '') {
      setIsAccounInputDisable(false);
      setValue('accountTypeId', '');
      BankAccount.getAccountSearch(`${loginNo}`)
        .then((res) => {
          if (res.id != null) {
            console.log('accoutTypeId ', res);
            setValue('accountTypeId', res.id);
            setAccountData(res);
            setIsAccounInputDisable(true);
          }
        })
        .catch((err) => {
          console.log("start Search Error", loginNo);
        });
    }
  };

  const onSubmit = (data) => {
    if (data !== '') {
      setSubmitState({ loading: true });
      const payload = {
        accountId: Number(data.accountTypeId),
        gateway: data.gateway,
        note: data.note,
        reason: data.reason,
        fee: Number(data.fee),
        amount: Number(data.amount),
      };

      Swal.fire({
        title: "Are you sure you want to Approve?",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: true,
        showCancelButton: true,
        text: "Once approved, you will not be able to approve this request!",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          setModal(false);
          transactionsService
            .postDeposit(payload)
            .then((res) => {
              setSubmitState({
                loading: false,
              });
              toast.success('Deposit Added Successfully');
              refreshRecord();
            })
            .catch((err) => {
              console.log('errrrrr', err);
              setSubmitState({
                loading: false,
              });
              toast.error('Deposit Failed ');
            });
          // alert('You submitted the form and stuff!');
        }
      });
    } else {
      errors.showMessages();
    }
  };

  const approveRequest = (id) => {
    Swal.fire({
      title: "Are you sure you want to Approve?",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      showCancelButton: true,
      text: "Once approved, you will not be able to approve this request!",
      icon: "success",
    }).then((res) => {
      if (res.isConfirmed) {
        alert("Item is Deleted");
      }
    });
  };
  
  const roleEntity = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.entity) || {}
  );
  
  return (
    <Fragment>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        toggler={toggle}
        isButtonShown={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FInput
                type='number'
                errors={errors}
                label='Login No:'
                name='login'
                register={register}
                placeholder='Enter Login No'
                value={data.login}
                validation={{ required: true }}
                handleOnChange={(e) => handleLoginChange(e)}
              />
            </Col>
            <Col md='12'>
              <FInput
                type='text'
                label='Name:'
                //name='login'
                placeholder={accountData.Customer?.firstName + ' ' + accountData.Customer?.lastName || ''}
                value={accountData.Customer?.firstName + ' ' + accountData.Customer?.lastName}
                disabled={true}
              />
            </Col>
            <Col md='6' style={{ display: 'none' }}>
              <FInput
                type='number'
                errors={errors}
                label='Account No:'
                name='accountTypeId'
                register={register}
                placeholder='Enter Account No'
                value={data.accountTypeId}
                validation={{ required: false }}
                disabled={isAccounInputDisable}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='6'>
              <FInput
                errors={errors}
                type='number'
                label='Amount:'
                name='amount'
                value={data.amount}
                register={register}
                placeholder='Enter your amount'
                validation={{ required: true }}
              />
            </Col>
            <Col md='6'>
       
            <FSelect
              control={control}
              errors={errors}
              label="Gateway"
              name="gateway"
              register={register}
              placeholder="Select Gateway"
              options={gatewayOptions}
              validation={{ required: true }}
              value={getValues('gateway') || ''}
              setValue={formValueChange}
              style={{
                color: "black",
                backgroundColor: "white",
                fontSize: "16px",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />




            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='6'>
              <FInput
                errors={errors}
                label='Reason:'
                name='reason'
                value={data.reason}
                register={register}
                placeholder='Enter Reason '
                validation={{ required: true }}
              />
            </Col>
            <Col md='6'>
              <FInput
                type='number'
                errors={errors}
                label='Fee:'
                name='fee'
                value={data.fee}
                register={register}
                placeholder='Enter fee'
                validation={{ required: true }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FTextArea
                errors={errors}
                label='Note:'
                name='note'
                value={data.note}
                register={register}
                placeholder='Enter Note'
              />
            </Col>
          </Row>
          <ModalFooter>
            <Btn
              attrBtn={{
                color: 'secondary',
                onClick: () => setModal(false),
                type: 'button',
              }}
            >
              {Close}
            </Btn>
            <Btn
              disabled={submitState.loading}
              attrBtn={{ color: 'primary' }}
            // onSubmit={() => handleMyFunction()}
            >
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default DepositFormModal;
