import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, ModalFooter } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useForm } from "react-hook-form";

import { FInput, FTextArea, FSelect } from 'CommonElements/Form';
import Swal from "sweetalert2";
import CommonModal from "../../../../src/Components/Common/Data/Ui-kits/Modals/common/modal";
import { gatewayService } from "Services";
import { toast } from "react-toastify";

const GatewayModal = ({ modal, NewMessage, toggle, setModal, refreshRecord }) => {
  const { register, handleSubmit, formState: { errors }, setValue, reset,control } = useForm();
  const formValueChange = (name, value) => {
    setValue(name, value);
  };
  const [submitState, setSubmitState] = useState({ loading: false });
    const [gatewayOptions, setGatewayOptions] = useState([]);

  // Reset form on modal close
  React.useEffect(() => {
    if (!modal) {
      reset({
        gatewayName: "",
        value: "",
      });
    }
  }, [modal]);

  // Handle form submission
  const onSubmit = async (data) => {
    if (data) {
      setSubmitState({ loading: true });
      const payload = {
        gatewayName: data.gatewayName,
        value: data.value,
        category: "withdrawal", // Auto-filled
        gatewayValue: data.gatewayName, // Auto-filled
      };

      Swal.fire({
        title: "Are you sure you want to add this Gateway?",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: true,
        showCancelButton: true,
        text: "Once added, the gateway will be available in the system!",
        icon: "success",
      }).then(async (res) => {
        if (res.isConfirmed) {
          try {
            await gatewayService.createGateway(payload);
            toast.success("Gateway Added Successfully");
            setModal(false);
            refreshRecord();
          } catch (error) {
            console.error("Error adding gateway:", error);
            toast.error("Failed to Add Gateway");
          }
          setSubmitState({ loading: false });
        }
      });
    }
  };

  
    useEffect(() => {
      gatewayService.getGateWay()
        .then((response) => {
         
          if (response) {
            const filteredGateways = response
              .filter(item => item.category === 'deposit')
              .map(item => ({ name: item.value, value: item.value }));
            
            setGatewayOptions([...filteredGateways]); // Ensures re-render
          }
        })
        .catch((error) => {
          console.error("Error fetching gateways:", error);
        });
    }, []);

  return (
    <Fragment>
      <CommonModal isOpen={modal} title={NewMessage} toggler={toggle} isButtonShown={true}>
        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ marginBottom: 10 }}>
            <Col md="12">
              <FInput
                errors={errors}
                label="Gateway Name"
                name="gatewayName"
                register={register}
                placeholder="Enter Gateway Name"
                validation={{ required: true }}
                handleOnChange={(e) => setValue("gatewayName", e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md="12">
            <FSelect
              control={control}
                errors={errors}
                label="Value"
                name="value"
                register={register}
                placeholder="Enter Value"
                options={gatewayOptions}
                validation={{ required: true }}
                setValue={formValueChange}
              
              />
            </Col>
          </Row>
          <ModalFooter>
            <Btn attrBtn={{ color: "secondary", onClick: () => setModal(false), type: "button" }}>
              Close
            </Btn>
            <Btn attrBtn={{ color: "primary" }} disabled={submitState.loading}>
              Save 
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default GatewayModal;
